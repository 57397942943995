<template>
	<div>
    <picture>
      <source srcset="/assets/modals/retail_circular.webp" type="image/webp" media="(min-width: 700px)" />
      <source srcset="/assets/modals/retail_circular.jpg" type="image/jpeg" media="(min-width: 700px)" />
      <source srcset="/assets/modals/retail_circular_mobile.webp" type="image/webp" />
      <img class="img-fluid" src="/assets/modals/retail_circular_mobile.jpg" :alt="$t('imgAlt')">
    </picture>
    <div class="red_bg"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h1 class="text-center">{{ $t('title') }}</h1>
          <p v-for="(item, index) in $t('copy1')" v-html="item"></p>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  name: 'RetailCircular'
}
</script>

<style lang="scss" scoped>
.modal picture {
  margin-top: -50px;
  display: block;
  img {
    display: block;
    width: 100%;
    max-width: none;
  }
}
.red_bg {
  padding: 25px 50px;
  margin-bottom: 50px;
}
</style>

<i18n>
{
  "en" : {
    "imgAlt" : "Trucks outside of a warehouse",
    "title" : "The Impact of a Circular Economy",
    "copy1" : [
      "A circular supply chain must be mindful of the big picture. Logistics companies are integrating sustainability efforts into their overall strategy by implementing new technologies to ensure responsible environmental practices. With the right technology, logistical carriers can maximize truckload optimization, meaning fewer trucks needed on the roads, resulting in less fuel consumption and carbon footprint. Technology can also provide end-to-end supply chain visibility enabling real-time insights needed to optimize supply chains and ensure they are sustainable. With the rise of direct-to-consumer shipments, supply chains need to be aware of how this high volume can and will impact the environment if they do not address how to optimize operations to be as “green” as possible.",
      "Aside from helping the environment, using green logistics can enhance company reputations, lower logistical costs, and increase customer loyalty."
    ]
  },
  "fr" : {
    "imgAlt" : "Trucks outside of a warehouse",
    "title" : "Économie circulaire",
    "copy1" : [
      "Une chaîne d’approvisionnement circulaire doit tenir compte de la situation dans son ensemble.  Les entreprises de logistique intègrent des efforts de développement durable dans leur stratégie globale en mettant en œuvre de nouvelles technologies pour garantir des pratiques environnementales responsables.  En optant pour la bonne technologie, les transporteurs logistiques peuvent optimiser le chargement des camions, ce qui se traduit par moins de camions sur les routes, une réduction de la consommation de carburant et une diminution de l’empreinte carbone.  La technologie permet également d’acquérir une visibilité de bout en bout de la chaîne d’approvisionnement grâce à l’accès en temps réel aux informations nécessaires à l’optimisation des chaînes d’approvisionnement dans un souci de durabilité.  Vu l’augmentation des expéditions directes aux consommateurs, les chaînes d’approvisionnement doivent prendre conscience de l’impact que ce volume élevé peut avoir et aura sur l’environnement si elles ne se penchent pas sur la manière d’optimiser les opérations afin de les rendre aussi « vertes » que possible.",
      "En plus de contribuer à l’environnement, le recours à une logistique verte peut améliorer la réputation de l’entreprise, réduire les coûts logistiques et fidéliser les clients."
    ]
  },
  "zh" : {
    "imgAlt" : "Trucks outside of a warehouse",
    "title" : "循环经济的影响",
    "copy1" : [
      "循环供应链必须关注大局。物流公司正在实施新的技术，将可持续性措施融入其总体战略，确保负责的环境实践。借助正确的技术，物流运输公司能够最大限度进行整车运输优化，减少途中所需的卡车数量，从而降低燃料消耗和碳足迹。技术还能够提供端到端的供应链可见性，从而带来优化供应链和确保其可持续性所需的实时见解。随着直接面向消费者发货的兴起，供应链需要意识到，如果不解决如何优化运营的问题，使其尽可能“绿色”，那么这么这种巨大的销售量会对环境造成多大的影响。",
      "除了帮助保护环境之外，采用绿色物流还能够提高公司声誉、降低物流成本并提升客户忠诚度。"
    ]
  },
  "gr" : {
    "imgAlt" : "Trucks outside of a warehouse",
    "title" : "Der Einfluss auf die Kreislaufwirtschaft",
    "copy1" : [
      "Eine kreisförmige Supply Chain darf das große Ganze niemals aus dem Auge verlieren. Logistik-Unternehmen setzen ihr Streben nach Nachhaltigkeit im Rahmen ihrer Gesamtstrategie um, indem sie neue Technologien für einen verantwortungsvollen Umgang mit der Umwelt einsetzen. Mithilfe der richtigen Technologie können Lieferunternehmen Lkw-Ladungen optimieren, was wiederumg weniger LKWs auf der Strasse bedeutet sowie einen reduzierten Kraftstoffverbrauch und ein geringerer CO2-Fußabdruck. Technologie kann darüber hinaus auch in der End-to-End-Supply Chain für Transparenz sorgen, indem sie Echtzeit-Einblicke ermöglicht, die für die Optimierung von Lieferketten notwendig sind und ihre Nachhaltigkeit gewährleisten. Mit der Erhöhung des Liefervolumens an Direktverbraucher müssen sich Supply Chains darüber im Klaren sein, wie sich dieses enorme Volumen auf die Umwelt auswirken kann und auswirken wird, wenn sie ihre Betriebsabläufe nicht optimieren und so „grün“ wie möglich gestalten.",
      "Abgesehen davon, dass die Umwelt geschont wird, kann der Einsatz von umweltfreundlicher Logistik den Ruf des Unternehmens verbessern, Logistikkosten senken und die Kundentreue erhöhen."
    ]
  }
}
</i18n>