<template>
<div>
  <div class="home logged_in">
    <div class="home_wrapper home_wrapper_halves">
      <div ref="left" class="home_half_left">
        <h2 class="fs1" v-html="$t('loggedIn.foodTitle')"></h2>
        <h3 class="fs2">{{ $t('loggedIn.foodSubtitle') }}</h3>
        <button class="btn-secondary on-red-bg" type="button" @click="navTo('food')">{{ $t('loggedIn.foodButton') }}</button>
      </div>

      <div ref="right" class="home_half_right">
        <h2 class="fs1" v-html="$t('loggedIn.retailTitle')"></h2>
        <h3 class="fs2">{{ $t('loggedIn.retailSubtitle') }}</h3>
        <button class="btn-secondary" type="button" @click="navTo('retail')">{{ $t('loggedIn.retailButton') }}</button>
      </div>
    </div>

    <div ref="wrapper" class="home_wrapper home_video_wrapper">
      <video v-if="isDesktop && connectionIsFast" class="home_bg_video" autoplay muted playsinline loop>
        <source v-if="windowWidth <= 426" :src="'/assets/media/home/video/Welcome_Intro_426.mp4'" type="video/mp4">
        <source v-else-if="windowWidth <= 640" :src="'/assets/media/home/video/Welcome_Intro_640.mp4'" type="video/mp4">
        <source v-else-if="windowWidth <= 960" :src="'/assets/media/home/video/Welcome_Intro_960.mp4'" type="video/mp4">
        <source v-else-if="windowWidth <= 1280" :src="'/assets/media/home/video/Welcome_Intro_1280.mp4'" type="video/mp4">
        <source v-else-if="windowWidth > 1280" :src="'/assets/media/home/video/Welcome_Intro_1920.mp4'" type="video/mp4">
      </video>

      <picture v-else class="home_bg_video">
        <source type="image/webp" srcset="/assets/home/home_video_bg_v2.webp">
        <img class="home_bg_video" src="/assets/home/home_video_bg_v2.jpg">
        </picture>

      <div ref="video_copy" class="home_video_copy">
        <h1 ref="video_heading" class="fs1 heading_shadow">{{ $t('loggedIn.overviewTitle') }}</h1>
        <h2 class="fs2 heading_shadow">{{ $t('loggedIn.overviewSubtitle') }}</h2>
        <button type="button" class="btn-secondary" @click="modalActive = true">{{ $t('loggedIn.overviewButton') }}</button>
      </div>
    </div>

    <div class="home_wrapper home_gm home_wrapper_padded">
      <div tabindex="0" class="home_gm_copy white">
        <h2 class="fs2 heading_shadow">&ldquo;{{ $t('loggedIn.gm.quote') }}&rdquo;</h2>
        <p class="heading_shadow">{{ $t('loggedIn.gm.copy') }}</p>
      </div>
      <img class="home_gm_bg" src="/assets/home/home_gm_bg.jpg">
    </div>

    <transition name="fading">
      <div class="home_scroll text-center" v-if="showScrollIcon">
        <svg class="home_scroll_icon" xmlns="http://www.w3.org/2000/svg" width="92" height="50" viewBox="0 0 92 50">
          <g transform="translate(-672 -798)">
            <g transform="translate(3.029 53.059)">
              <g transform="translate(697.971 744.941)" fill="none" stroke="#fff" stroke-width="2">
                <rect width="29" height="43" rx="14.5" stroke="none"/>
                <rect x="1" y="1" width="27" height="41" rx="13.5" fill="none"/>
              </g>
              <rect id="home_scroll_icon_anim" width="4" height="9" rx="2" fill="#fff"/>
            </g>
          </g>
        </svg>
        <span>{{ $t('loggedIn.mouseIconText') }}</span>
      </div>
    </transition>

    <VideoModal :video="video" v-if="modalActive" @closeModal="modalActive = false"></VideoModal>

    <Footer></Footer>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import VideoModal from "@/components/VideoModal.vue";
import Footer from "@/components/Footer.vue";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger); 

export default {
  name: 'Home',
  metaInfo: {
    title: 'Avery Dennison Digital Supply Chain Experience | Avery Dennison',
    titleTemplate: null,
    meta: [
        {
          name: "description",
          content:
            "Avery Dennison's Digital Experience for Food Supply Chain Solutions and Logistics & Supply Chain Solutions. Walk through every step of Avery Dennison's supply chain digitally."
        }
      ]
  },
  components: {
    VideoModal,
    Footer
  },
  computed: {
    connectionIsFast: function(){
      return this.$parent.connection == 'fast'
    }
  },
  data() {
    return {
      video: '/home/video/Welcome',
      modalActive: false,
      windowHeight: 0,
      windowWidth: 0,
      showScrollIcon: true,

      isDesktop: false,

      halfRight: null,
      halfLeft: null
    }
  },
  methods: {
    onScroll: function(){
      let scrollPos = window.pageYOffset

      if (scrollPos > this.windowHeight) {
        this.showScrollIcon = false;
      } else {
        this.showScrollIcon = true;
      }
    },
    navTo: function(vertical) {
      let $this = this
      setTimeout(function(){ $this.$router.push(vertical) },400)
    }
  },
  async mounted(){
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth

    this.isDesktop = this.windowWidth > 1024;

    window.addEventListener('scroll', this.onScroll)
  },
  destroyed(){
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.home.logged_in {
  background-color: transparent;
  overflow: hidden;
  
  .home_wrapper {
    height: calc(100 * var(--vh));
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  .home_video_wrapper {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    align-items: flex-start;
    overflow: hidden;
    //position: fixed;

    @include screenLarge {
      height: 100vh;
      align-items: center;
      width: 100%;
      display: block !important;
    }
  }

  .home_bg_video {
    min-width: 100vw;
    min-height: calc(100 * var(--vh));
    position: fixed;
    z-index: -1;
    @include screenLarge {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
  .home_video_copy {
    height: calc(100 * var(--vh));
    z-index: 1;
    text-align: center;
    padding: 15px;
    width: 100%;
    background-color: rgba(36, 36, 36, 0.25);
    opacity: 0.999;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1, h2 {
      color: $colorWhite;
      width: 90%;
      @include screenSmall {
        width: 80%;
      }
      @include screenMedium {
        max-width: 700px;
      }
    }
  }
  
  .home_wrapper_halves {
    display: block;
  }
  .home_half_left, .home_half_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100vw;
    height: calc(50 * var(--vh));
    padding: 30px;
    opacity: 0.999;

    @media (max-width: 1024px) {
      transform: translate(0px, 0px) !important;
    }

    @include homeBreakpoint {
      position: absolute;
      width: 50vw;
      max-width: 50%;
      padding: 50px 10vw;
      height: 100%;
    }

    &.text_fade * {
      opacity: 0;
      transition: all 0.5s 0.25s;
    }

    h2, h3 {
      color: $colorWhite;
    }

    * {
      transition: transform 0.5s;
    }
  }
  .home_half_left {
    background-color: $colorRed;
  }
  .home_half_right {
    background-color: #ee2b2c;
    @include screenMedium {
      right: 0;
    }
  }
  .home_gm {
    opacity: 0.999;
    position: relative;

    &_bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      bottom: 0;
      z-index: -1;
    }
  }
  .home_gm_copy {
    text-align: center;
    padding: 25px;
    padding-top: 150px;

    @include screenSmall {
      position: absolute;
      text-align: left;
      width: 50%;
      left: 50%;
      padding-left: 0;
      padding-top: 25px;
    }
    @include screenMedium {
      width: 450px;
      left: 50vw;
    }
    @include screenLarge {
      width: 450px;
      left: auto;
      right: calc(25vw - 250px);
    }

    p {
      font-weight: 700;
    }

    .fs2 {
      margin-bottom: 20px;
      color: $colorWhite;
      &:first-letter {
        margin-left: -10px;

        @include screenLarge {
          margin-left: -15px;
        }
      }
    }

    &:not(:focus-visible) {
      outline: none;
    }
  }


  .home_scroll {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    &_icon {
      display: block;
      margin: auto;
      width: 75px;
    }
    span {
      display: block;
      color: $colorWhite;
      font-weight: bold;
      font-size: 13px;
    }
  }
  #home_scroll_icon_anim {
    transform: translate(710.971px, 753.941px);
    opacity: 1;
    animation: scroll 1.5s linear infinite;
  }
}

.fr .home.logged_in {
  .home_half_left, .home_half_right {
    @include screenMedium {
      padding: 50px 5vw;
    }

    h2 {
      @include screenMedium {
        font-size: 40px;
      }
      @include screenLarge {
        font-size: 50px;
      }
    }
  }
  .home_video_copy {
    .fs1, .fs2 {
      @include screenMedium {
        max-width: 780px;
      }
    }
  }
}

.zh .home.logged_in {
  .home_half_left, .home_half_right {
    @include homeBreakpoint {
      padding: 50px 8vw;
    }
  }
  .home_video_copy h2 {
    max-width: 750px;
  }
}

footer {
  opacity: 0.999;
}

@keyframes scroll{
  0%{
    transform: translate(710.971px, 753.941px);
    opacity: 1;
  }
  20%{
    transform: translate(710.971px, 756.941px);
    opacity: 1;
  }
  80%{
    transform: translate(710.971px, 768.941px);
    opacity: 0;
  }
  100%{
    transform: translate(710.971px, 768.941px);
    opacity: 0;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
  .home_video_wrapper .plyr {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    align-items: flex-start;
    overflow: hidden;
    position: fixed;

    @include screenLarge {
      height: 100vh;
      align-items: center;
      width: 100%;
      display: block !important;
    }

    video {
      width: auto;
    }
  }
</style>


<i18n>
{
  "en" : {
    "loggedIn" : {
      "overviewTitle" : "Welcome to the future of the supply chain.",
      "overviewSubtitle" : "Improved labor efficiency, safety, traceability, transparency, and sustainability",
      "overviewButton" : "Play Overview Video",
      "foodTitle" : "Food Supply Chain Solutions",
      "foodSubtitle" : "From farm to table",
      "foodButton" : "Explore supply chain",
      "retailTitle" : "Logistics and Supply Chain Solutions",
      "retailSubtitle" : "From source to store",
      "retailButton" : "Explore supply chain",
      "mouseIconText" : "Scroll Down",
      "gm" : {
        "heading" : "A Word From Our GM",
        "copy" : "Ryan Yost, VP and GM of Avery Dennison Identification Solutions",
        "quote" : "COVID accelerated the role of technology for supply chains. What we thought was on the horizon for 2025 was accelerated in 2021."
      }
    }   
  },
  "fr" : {
    "loggedIn" : {
      "overviewTitle" : "Bienvenue dans la chaîne d’approvisionnement du futur",
      "overviewSubtitle" : "Amélioration de l’efficacité au travail, de la sécurité, de la traçabilité, de la transparence et de la durabilité",
      "overviewButton" : "Lire la vidéo de présentation",
      "foodTitle" : "Solutions pour la chaîne d’approvisionnement alimentaire",
      "foodSubtitle" : "De la ferme à l’assiette",
      "foodButton" : "Explorer la chaîne d’approvisionnement",
      "retailTitle" : "Solutions intelligentes pour la chaîne d’approvisionnement du commerce de détail",
      "retailSubtitle" : "De la source au magasin",
      "retailButton" : "Explorer la chaîne d’approvisionnement",
      "mouseIconText" : "Défilement vers le bas",
      "gm" : {
        "heading" : "Nullam id dolor id nibhes ultri vehicula ut id elit.",
        "copy" : "Ryan Yost, VP and GM of Avery Dennison Identification Solutions",
        "quote" : "L’épidémie de COVID a accéléré le rôle de la technologie pour les chaînes d’approvisionnement. Ce que nous avions prévu à l’horizon 2025 sera mis en place en 2021."
      }
    }  
  },
  "zh" : {
    "loggedIn" : {
      "overviewTitle" : "欢迎来到供应链的未来。",
      "overviewSubtitle" : "更高的劳动效率、安全性、可追溯性、透明度和可持续性",
      "overviewButton" : "播放概述视频",
      "foodTitle" : "食品供应链<br>解决方案",
      "foodSubtitle" : "从农场到餐桌",
      "foodButton" : "探索供应链",
      "retailTitle" : "零售供应链<br>解决方案",
      "retailSubtitle" : "从源头到商店",
      "retailButton" : "探索供应链",
      "mouseIconText" : "向下滚动",
      "gm" : {
        "heading" : "A Word From Our GM",
        "copy" : "Ryan Yost，艾利丹尼森打印机解决方案副总裁兼总经理",
        "quote" : "新冠疫情促进了技术在供应链中的作用。我们原以为要等到 2025 年才能看到的景象，将在 2021 年就能出现。"
      }
    }   
  },
  "gr" : {
    "loggedIn" : {
      "overviewTitle" : "Willkommen in der Zukunft der Supply Chain.",
      "overviewSubtitle" : "Optimierte Arbeitseffizienz, Sicherheit, Rückverfolgbarkeit, Transparenz und Nachhaltigkeit.",
      "overviewButton" : "Das Überblick-Video abspielen",
      "foodTitle" : "Supply-Chain-Lösungen für Lebensmittel",
      "foodSubtitle" : "Vom Erzeuger zum Verbraucher",
      "foodButton" : "Die Supply Chain kennenlernen",
      "retailTitle" : "Logistik & Lieferkette",
      "retailSubtitle" : "Vom Erzeuger bis ins Regal",
      "retailButton" : "Die Supply Chain kennenlernen",
      "mouseIconText" : "Herunterscrollen",
      "gm" : {
        "heading" : "A Word From Our GM",
        "copy" : "Ryan Yost, VP und GM von Avery Dennison Identifikationslösungen",
        "quote" : "COVID 19 hat die Bedeutung von Technologien in Supply Chains verstärkt. Das, von dem wir dachten, dass es sich 2025 am Horizont abzeichnen würde, wird schon 2021 umgesetzt werden."
      }
    }   
  }
}
</i18n>