import { render, staticRenderFns } from "./RequestDemo.vue?vue&type=template&id=d59a9c04&scoped=true&"
import script from "./RequestDemo.vue?vue&type=script&lang=js&"
export * from "./RequestDemo.vue?vue&type=script&lang=js&"
import style0 from "./RequestDemo.vue?vue&type=style&index=0&id=d59a9c04&prod&lang=scss&scoped=true&"
import style1 from "./RequestDemo.vue?vue&type=style&index=1&id=d59a9c04&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d59a9c04",
  null
  
)

/* custom blocks */
import block0 from "./RequestDemo.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports