<template>
  <div class="language_switcher">

    <select v-model="selectedLanguage" @change="updateLanguage">
    	<option value="en">English</option>
    	<option value="fr">Français</option>
      <option value="zh">中文</option>
      <option value="gr">Deutsch</option>
    </select>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'LanguageSwitcher',
  computed: {
    ...mapGetters({
      language: "locale",
    })
  },
  data() {
    return {
      selectedLanguage: 'en',
    };
  },
  methods: {
    updateLanguage() {

      this.$i18n.locale = this.selectedLanguage;
      this.$store.dispatch("setLanguage", this.selectedLanguage);
    }
  },
  mounted() {
  	if (this.language.locale) {
  		this.selectedLanguage = this.language.locale
      this.$i18n.locale = this.selectedLanguage;
  	} 
  },
  watch: {
    language: function () {
      this.selectedLanguage = this.language.locale
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: 16px;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    color: inherit;
    z-index: 10;

    &::-ms-expand {
      display: none;
    }

    option {
      color: #000;
    }
  }
  .language_switcher {
    width: 120px;
    border-radius: 0;
    padding: 5px 5px 4px 5px;
    font-size: 14px;
    cursor: pointer;
    line-height: 1.1;
    background-color: transparent;
    position: relative;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath fill='%23ffffff' d='M152.1 236.2c-3.5-12.1-7.8-33.2-7.8-33.2h-.5s-4.3 21.1-7.8 33.2l-11.1 37.5H163zM616 96H336v320h280c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zm-24 120c0 6.6-5.4 12-12 12h-11.4c-6.9 23.6-21.7 47.4-42.7 69.9 8.4 6.4 17.1 12.5 26.1 18 5.5 3.4 7.3 10.5 4.1 16.2l-7.9 13.9c-3.4 5.9-10.9 7.8-16.7 4.3-12.6-7.8-24.5-16.1-35.4-24.9-10.9 8.7-22.7 17.1-35.4 24.9-5.8 3.5-13.3 1.6-16.7-4.3l-7.9-13.9c-3.2-5.6-1.4-12.8 4.2-16.2 9.3-5.7 18-11.7 26.1-18-7.9-8.4-14.9-17-21-25.7-4-5.7-2.2-13.6 3.7-17.1l6.5-3.9 7.3-4.3c5.4-3.2 12.4-1.7 16 3.4 5 7 10.8 14 17.4 20.9 13.5-14.2 23.8-28.9 30-43.2H412c-6.6 0-12-5.4-12-12v-16c0-6.6 5.4-12 12-12h64v-16c0-6.6 5.4-12 12-12h16c6.6 0 12 5.4 12 12v16h64c6.6 0 12 5.4 12 12zM0 120v272c0 13.3 10.7 24 24 24h280V96H24c-13.3 0-24 10.7-24 24zm58.9 216.1L116.4 167c1.7-4.9 6.2-8.1 11.4-8.1h32.5c5.1 0 9.7 3.3 11.4 8.1l57.5 169.1c2.6 7.8-3.1 15.9-11.4 15.9h-22.9a12 12 0 0 1-11.5-8.6l-9.4-31.9h-60.2l-9.1 31.8c-1.5 5.1-6.2 8.7-11.5 8.7H70.3c-8.2 0-14-8.1-11.4-15.9z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: top 3px right 10px;

    &:after {
      width: 25px;
      display: block;
      position: absolute;
      right: 30px;
      top: 2px;
      z-index: 0;
    }

    &:focus-within {
      background-color: rgba($colorWhite, 0.2);
      &:after {
        content: "";
        width: 25px;
        // height: 6px;
        display: block;
        position: absolute;
        right: 30px;
        top: 2px;
        z-index: 0;
      }
    }
  }
</style>