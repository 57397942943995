import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import VuePlyr from 'vue-plyr'

import VueMeta from 'vue-meta'

import {firebase, firebaseanalytics} from "./firebase" 

Vue.config.productionTip = false

Vue.prototype.$analytics = firebaseanalytics;

// initially set analytics collection to false
// until user accepts use of cookies
firebaseanalytics.setAnalyticsCollectionEnabled(false)

const vapp = new Vue({
  store,
  router,
  i18n,
  created(){},
  render: h => h(App)
})

router.onReady(() => vapp.$mount('#app'))
// Vue.use(VueTelInput, {
// 	preferredCountries: ['us', 'uk', 'fr']
// })
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
})

Vue.use(VuePlyr, {
	plyr: {
		controls: [
		    'play-large', // The large play button in the center
		    'play', // Play/pause playback
		    'progress', // The progress bar and scrubber for playback and buffering
		    'current-time', // The current time of playback
		    'duration', // The full duration of the media
		    'mute', // Toggle mute
		    'volume', // Volume control
		    'captions', // Toggle captions
		    'settings', // Settings menu
		    'fullscreen', // Toggle fullscreen
		]
	}
	
})