<template>
  <div class="sticky_footer">
    <nav>
      <div class="sticky_footer_explore"><span>{{$t('explore')}} <img src="/assets/icons/arrow-white-right.svg" alt="Arrow"></span></div>

      <transition :name="transition" mode="out-in">
        <ul v-if="verticalMenu == 'food'" class="sticky_footer_menu unstyled">
          <li><router-link to="/food/grower">{{ $t('verticals.food.grower') }}</router-link></li>
          <li><router-link to="/food/processor">{{ $t('verticals.food.processor') }}</router-link></li>
          <li><router-link to="/food/dc">{{ $t('verticals.food.dc') }}</router-link></li>
          <li><router-link to="/food/stockroom">{{ $t('verticals.food.boh') }}</router-link></li>
          <li><router-link to="/food/kitchen">{{ $t('verticals.food.kitchen') }}</router-link></li>
          <li><router-link to="/food/restaurant">{{ $t('verticals.food.restaurant') }}</router-link></li>
          <li><router-link to="/food/grocery">{{ $t('verticals.food.grocery') }}</router-link></li>
        </ul>
      </transition>

      <transition :name="transition" mode="out-in">
        <ul v-if="verticalMenu == 'retail'" class="sticky_footer_menu unstyled">
          <li><router-link to="/retail/manufacturing">{{ $t('verticals.retail.manufacturing') }}</router-link></li>
          <li><router-link to="/retail/first-mile">{{ $t('verticals.retail.first') }}</router-link></li>
          <li><router-link to="/retail/distribution">{{ $t('verticals.retail.dc') }}</router-link></li>
          <li><router-link to="/retail/fulfillment">{{ $t('verticals.retail.fulfillment') }}</router-link></li>
          <li><router-link to="/retail/last-mile">{{ $t('verticals.retail.last') }}</router-link></li>
          <li><router-link to="/retail/store">{{ $t('verticals.retail.store') }}</router-link></li>
          <li><router-link to="/retail/destination">{{ $t('verticals.retail.final') }}</router-link></li>
        </ul>
      </transition>

    </nav>
  </div>
</template>
<script>

export default {
  props: {
    verticalMenu: String,
    transition: String
  },
  computed: {

  },
  methods: {
    
  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.sticky_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  padding-left: 0;
  background-color: $colorWhite;
  z-index: 997;
  display: block;
  border-top: solid 2px #e8e8e8;

  &_explore {
    background: $colorRed;
    padding: 20px 15px;
    z-index: 2;
    & > span {
      color: $colorWhite;
      display: flex;
      align-items: center;
      padding: 1.5px 0;
      border-bottom: 0;
      & > img {
        margin-left: 15px;
      }
    }
  }

  &_menu {
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: absolute;
    padding-left: 108px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    max-height: 62px;  
  }
}
nav {
  display: flex;

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;  
    max-height: 62px;  
  }

  li {
    display: flex;
    align-items: center;
    padding: 0;
    white-space: nowrap;
    &:first-of-type::before, &:last-of-type::after {
      content: none;
    }

    &::before, &::after {
      content: '';
      display: block;
      width: 1px;
      height: 25px;
      background: #e8e8e8;
    }

    &:hover, &:focus-within {
      background-color: $colorRed;
      &::before, &::after {
        background: $colorRed;
      }
      a {
        color: $colorWhite;
        outline: none;
      }
      .router-link-exact-active:after {
        background-color: $colorWhite;
      }
    }    
  }

  a, span {
    padding: 23px 15px 21px;
    color: $colorGreyDark;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-size: 14px;
    font-weight: 700;
    position: relative;

    &.router-link-exact-active:after {
      display: block;
      position: absolute;
      content: '';
      width: calc(100% - 30px);
      margin: auto;
      left: 0;
      right: 0;
      height: 4px;
      bottom: 10px;
      background-color: $colorRed;
      border-radius: 4px;
    }
  }
}

.zh {
  .sticky_footer_menu {
    padding-left: 87px;
  }
}
.gr {
  .sticky_footer_menu {
    padding-left: 127px;
  }
}
</style>

<i18n>
{
  "en" : {
    "explore" : "Explore"
  },
  "fr" : {
    "explore" : "Marchés"
  },
  "zh" : {
    "explore" : "探索"
  },
  "gr" : {
    "explore" : "Entdecken"
  }
}
</i18n>