<template>
  <footer v-bind:class="{sticky_padding : hasSticky}">
    <div class="container-fluid">
      <div class="row flex-md-row-reverse">
        <div class="col-md-4 footer_logo">
          <img src="/assets/ad-making-possible-website-footer-red.png" alt="Making Possible. Avery Dennison Logo" class="logo">
        </div>
        <div class="col-md-8 footer_content">
          <p><strong>&copy; {{ year }} {{ $t('copy') }}</strong></p>
          <p><router-link to="/cookies">{{ $t('cookies') }}</router-link> | <router-link to="/privacy">{{ $t('privacy') }}</router-link></p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>

export default {
  computed: {
    hasSticky: function(){
      if (this.$route.meta.vertical) { 
        return true; 
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      windowWidth: 0,
      year: new Date().getFullYear()
    }
  },
  methods: {
    onResize: function(){
      this.windowWidth = window.innerWidth
    }
  },
  mounted(){
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.onResize)
  },
  destroyed(){
    window.removeEventListener('resize', this.onResize)
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.sticky_padding {
  padding-bottom: 58px;
}

footer {
  background-color: #f0f0f0;

  .row {
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: flex-end;

    @include screenMedium {
      padding-top: 35px;
      padding-bottom: 35px;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

}

.footer_logo {
  margin-bottom: 15px;
  @include screenSmall {
    margin-bottom: 0;
    text-align: right;
  }
  .logo {
    width: 220px;
     @include screenSmall {
      margin-top: 10px;
    }
  }
}

.footer_content {
  a {
    color: $colorGreyDark;
    text-decoration: none;

    &:hover {
      color: $colorRed;
    }
  }
}
</style>

<i18n>
{
  "en" : {
    "copy" : "Avery Dennison Corporation. All Rights Reserved.",
    "cookies" : "Cookie Policy",
    "privacy" : "Legal & Privacy Notices"
  },
  "fr" : {
    "copy" : "Avery Dennison Corporation. Tous droits réservés.",
    "cookies" : "Politique relative aux cookies",
    "privacy" : "Mentions légales et politique de confidentialité"
  },
  "zh" : {
    "copy" : "© 2021 艾利丹尼森公司。保留所有权。",
    "cookies" : "Cookie 政策",
    "privacy" : "法律与隐私声明"
  },
  "gr" : {
    "copy" : "Avery Dennison Corporation. Alle Rechte vorbehalten",
    "cookies" : "Cookie-Richtlinie",
    "privacy" : "Rechtliche Hinweise & Datenschutzrichtlinie"
  }
}
</i18n>