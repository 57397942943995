import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cookies: null,
    locale :  '',
    isointro: false,
    fsma: false,
  },
  getters: {
    cookies(state) {
      return state.cookies
    },
    locale(state){
      return state.locale
    },
    isointro(state){
      return state.isointro
    },
    fsma(state){
      return state.fsma
    },
  },
  mutations: {
    SET_COOKIES(state, value) {
      state.cookies = value
    },
    SET_LANGUAGE(state, data) {
      state.locale = data;
    },
    SET_ISOINTRO(state, data) {
      state.isointro = data;
    },
    SET_FSMA(state, data) {
      state.fsma = data;
    },
  },
  actions: {
    setCookies({commit}, value){
      commit("SET_COOKIES", value)
    },
    setIsoIntro({commit}, closed) {
      commit("SET_ISOINTRO", {
        isointro: closed
      })
    },
    setFSMA({commit}, closed) {
      commit("SET_FSMA", {
        fsma: closed
      })
    },
    setLanguage({commit}, language) {
      commit("SET_LANGUAGE", {
        locale: language
      })
    },
  },

  plugins: [createPersistedState()]
});
