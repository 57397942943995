<template>
  <div class="demo">
    <button class="demo_button" type="button" @click="updateModal()"><span>{{ $t('request') }}</span></button>

    <Modal v-if="modal" width="half" :header="$t('request')" @closeModal="updateModal()">
      <div class="demo_modal_content">
        <div>
          <script id="aoform-script-49e7722f-b79a-40e8-8580-995c19e85326:d-0001" type="application/javascript">
            function loadForm() {
              if (_aoFormLoader) {
                _aoFormLoader.load({
                  id: "49e7722f-b79a-40e8-8580-995c19e85326:d-0001",
                  accountId: "5774",
                  domain: "rbis-solutions.averydennison.com",
                  isTemp: false,
                  noStyle: false,
                  prefill: false
                })
              } else {
                setTimeout(function(){ loadForm(); }, 100)
              }
            }
            loadForm();
          </script>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Modal from "@/components/Modal.vue";

export default {
  name: 'RequestDemo',
  components: {
    Modal,
  },
  props: {
    productId: String
  },
  computed: {
    email: function(){
      return '';
    },
    market: function(){
      const $t = this.$t.bind(this)

      switch(this.productId.substring(0,3)){
        case 'gro':
          return $t('verticals.food.grower')
          break;
        case 'pro':
          return $t('verticals.food.processor')
          break;
        case 'DCF':
          return $t('verticals.food.dc')
          break;
        case 'BOH':
          return $t('verticals.food.boh')
          break;
        case 'kit':
          return $t('verticals.food.kitchen')
          break;
        case 'Res':
          return $t('verticals.food.restaurant')
          break;
        case 'FSt':
          return $t('verticals.food.grocery')
          break;

        case 'Man':
          return $t('verticals.retail.manufacturing')
          break;
        case 'Fir':
          return $t('verticals.retail.first')
          break;
        case 'DCR':
          return $t('verticals.retail.dc')
          break;
        case 'Ful':
          return $t('verticals.retail.fulfillment')
          break;
        case 'Las':
          return $t('verticals.retail.last')
          break;
        case 'Sto':
          return $t('verticals.retail.store')
          break;
        case 'Fin':
          return $t('verticals.retail.final')
          break;
      }
    },
    vertical: function(){
      const $t = this.$t.bind(this)
      switch(this.productId.substring(0,3)){
        case 'gro':
        case 'pro':
        case 'DCF':
        case 'BOH':
        case 'kit':
        case 'Res':
        case 'FSt':
          return $t('verticals.food.title')

        case 'Man':
        case 'Fir':
        case 'DCR':
        case 'Ful':
        case 'Las':
        case 'Sto':
        case 'Fin':
          return $t('verticals.retail.title')
      }
    },
    solution: function(){
      const $t = this.$t.bind(this)
      return $t('products.' + this.productId + '.title')
    },
    product: function(){
      const $t = this.$t.bind(this)
      return $t('products.' + this.productId + '.product')
    },
    userEmailContent: function(){
      const $t = this.$t.bind(this)

      var intro = $t('emails.user.intro')
      var contact = $t('emails.user.contact', {0: this.user.data.contact })
      var productInfo =  $t('emails.user.productInfo', {1: this.solution, 2: this.product, 3: this.vertical, 4: this.market })
      var thanks = $t('emails.user.thanks')
      var team = $t('emails.user.team')

      return [intro, contact, productInfo, thanks, team]
    },
    averyEmailContent: function(){
      var contactInfo = this.user.data.email
      if (this.user.data.contact == "phone") {
        contactInfo += ' ' + this.user.data.phone
      }

      const $t = this.$t.bind(this)

      var regionCountry = $t('regions.' + this.user.data.region) + ' - ' + this.user.data.country;

      // can set language here -- all avery emails are in english, regardless of user lang
      var intro = $t('emails.avery.intro', 'en')
      var contact = $t('emails.avery.contact', 'en', {0: this.user.data.displayName, 1: this.user.data.contact, 2: contactInfo })
      var productInfo =  $t('emails.avery.productInfo', 'en', {1: $t('products.' + this.productId + '.title', 'en'), 2: $t('products.' + this.productId + '.product', 'en'), 3: this.vertical, 4: this.market })
      var location = $t('emails.avery.location', 'en', {1: regionCountry }) 
      var company = 'Company: ' + this.user.data.company;

      return [intro, contact, productInfo, company, location]
    }
  },
  data() {
    return {
      modal: false,
    }
  },
  methods: {
    updateModal: function(){
      this.modal = !this.modal
    },
    sendUserEmail: function(){
      // firebase.firestore().collection('mail').add({
      //   to: this.user.data.email,
      //   template: {
      //     name: 'user_requestdemo',
      //     data: {
      //       paragraphs: this.userEmailContent
      //     }
      //   }
      // })
    },
    sendAveryEmail: function(){
      var averyemail;

      switch (this.user.data.region) {
        case 'na':
          averyemail = 'ris.dmail@averydennison.com';
          break;

        case 'eu':
          averyemail = 'richard.w.roberts@eu.averydennison.com';
          break;

        case 'me':
          averyemail = 'shreyas.s@eu.averydennison.com';
          break;

        case 'as':
        case 'aus':
          averyemail = 'sales.nsw@ap.averydennison.com';
          break;

        default: 
          averyemail = 'ris.dmail@averydennison.com';
      }
      
      firebase.firestore().collection('mail').add({
        to: averyemail,
        subject: 'Someone requested a demo',
        template: {
          name: 'avery_requestdemo',
          data: {
            //paragraphs: this.averyEmailContent
          }
        }
      })
    }
  },
  watch: {
    modal: function () {
      let val = this.modal ? 'demo_modal_open' : '';
      this.$emit('requestDemoClass', val);
    }
  },
  mounted (){
    // if (this.demos.indexOf(this.productId) !== -1){
    //   this.requested = true;
    // }

    // if (this.user.data.contact != null) { this.form.contact = this.user.data.contact}
    // if (this.user.data.phone != null) { this.form.phone = this.user.data.phone}
    // if (this.user.data.region != null) { this.form.region = this.user.data.region}
    // if (this.user.data.country != null) { this.form.country = this.user.data.country}
  }
}
</script>


<style lang="scss" scoped>
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_forms.scss';

  .demo_button {
    border: 0;
    padding: 6px 25px;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: $colorGrey;
    cursor: pointer;

    svg {
      padding-bottom: 3px;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: $colorGrey;
    }
    &:hover span {
      color: darken($colorGrey, 10%);
    }
    &:focus {
      outline: none;
      span {
        color: $colorRed;
        text-decoration: underline;
      }
    }
    &[disabled] {
      cursor: default;
      span {
        color: $colorGreyLight;
      }
    }
  }

  .product_details_cta_wrapper .demo_button {
    padding: 6px 10px;
    @include screenXSmall {
      padding: 6px 25px;
    }
  }

  .modal, .product_details {
    .demo_button {
      @include btnDefault($colorWhite, $colorRed);
      margin: 20px auto;

      @include screenLarge {
        margin: 50px auto 20px;
      }

      &:hover {
        background-color: darken($colorRed, 5%);
      }

      span {
        font-size: 16px;
        font-weight: 700;
        color: $colorWhite;
      }

      &[disabled] {
        cursor: default;
        background-color: $colorGreyLight;
        border-color: $colorGreyLight;

        span {
          background-color: $colorGreyLight;
        }
      }

      &:focus {
        border-color: $colorRed;
        background-color: $colorWhite;
        span {
          color: $colorRed;
        }
      }
    }
  }

  .demo .modal.red_header {
    .red_bg {
      h3 {
        margin-bottom: 30px;
      }
    }
  }

  .product_details .demo {
    text-align: center;
  }


  .demo_modal_content {
    padding-left: 15px;
      padding-right: 15px;

    @include screenSmall {
      padding-left: 30px;
      padding-right: 30px;
    }
    
  }

  
  .navbar .demo_button {
    @include btnDefault($colorRed, $colorWhite);
    min-width: 0 !important;
    padding: 5px 15px !important;
    margin-right: 15px;
    span {
      color: $colorRed;
    }
    &:hover {
      background: rgba($colorRed, 0.8) !important;
      border-color: $colorWhite;
      color: $colorWhite;
      span {
        color: $colorWhite;
      }
    }
    &:focus {
      border-color: $colorRed;
    }
  }
</style>

<style lang="scss">
  .demo_button ~ .modal_wrapper .modal {
    @media (max-width: 567px) {
      padding-bottom: 20px;
    }

    // @media (min-width: 992px) {
    //   height: auto;
    //   top: 50px;
    //   bottom: auto;
    // }

    .header h3 {
      @media (max-width: 567px) {
        margin-bottom: 20px;
      }
    }
  }

  // .demo .modal_half {
  //   width: clamp(360px, 50vw, 400px);
  //   left: clamp(calc(50vw - 200px), 25vw, calc(50vw - 180px));
  //   form {
  //     margin: 0 auto;
  //   }
  // }

  // external forms
  @import '@/assets/scss/_variables.scss';
  .ao-form .ao-column-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .ao-block-wrapper {
      width: 100%;
      @include screenMedium {
        width: 49%;
      }
    }
    .ao-block-wrapper:nth-child(n+8) {
      @include screenMedium {
        width: 100%;
      }
    }
    > div:last-of-type {
      @include screenMedium {
        width: 100% !important;
      }

      .ao-richtext-block > p {
        color: $colorRed;
        font-size: 22px;
        margin-bottom: 0;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  .ao-form label.ao-form-label {
    color: $colorGrey;
    text-transform: none;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 6px;
    display: block;

    span {
      font-size: 12px;
    }
  }
  .ao-form-submit {
    @include btnDefault($colorWhite, $colorRed);
    display: inline-block;
    &:hover {
      background-color: $colorRed;
      border-color: $colorRed;
      color: $colorWhite;
    }
    &:focus {
      border-color: $colorRed;
      color: $colorRed;
      background-color: $colorWhite;
    }
  }
</style>
<i18n>
{
  "en" : {
    "request" : "Request a Demo",
    "copy" : "Yes, I agree to receive information about products and services as well as other information from Avery Dennison.",
    "form" : {
      "country" : "Country or US State",
      "region" : "Select your region",
      "regions" : [
        [ "na", "North America" ],
        [ "eu", "Europe" ],
        [ "me", "Middle East" ],
        [ "as", "Asia" ],
        [ "aus", "Australia" ],
        [ "other", "Other" ]
      ],
      "contact" : "Contact Preference",
      "email" : "Email",
      "phone" : "Phone",
      "submit" : "Submit"
    },
    "error" : {
      "phone" : "Phone number is not valid",
      "country" : "Country must be between 1 and 50 characters long"
    },
    "thanks" : "Thanks for requesting a demo! We will be in touch soon.",
    "emails" : {
      "user" : {
        "intro" : "Thanks for requesting a demo!",
        "contact" : "Someone will be in touch with you by {0} soon.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "thanks" : "Thanks,",
        "team" : "The Avery Dennison Team"
      },
      "avery" : {
        "intro" : "Someone has requested a demo.",
        "contact" : "{0} has requested someone contact them by {1} at {2}.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "location" : "Location: {1}"
      }
    }
  },
  "fr" : {
    "request" : "Demander une démonstration",
    "copy" : "Yes, I agree to receive information about products and services as well as other information from Avery Dennison.",
    "form" : {
    "country" : "Pays de résidence",
      "region" : "Région/Pays",
      "regions" : [
        [ "na", "Amérique du Nord" ],
        [ "eu", "Europe" ],
        [ "me", "Moyen-Orient" ],
        [ "as", "Asie" ],
        [ "aus", "Australie" ],
        [ "other", "Autre" ]
      ],
      "contact" : "Préférence de communication",
      "email" : "E-mail",
      "phone" : "Téléphone",
      "submit" : "Envoyer"
    },
    "error" : {
      "phone" : "Le numéro de téléphone n’est pas valable.",
      "country" : "Pays de résidence doit comporter entre 1 à 50 caractères."
    },
    "thanks" : "Merci d’avoir demandé une démonstration ! Nous vous contacterons prochainement.",
    "emails" : {
      "user" : {
        "intro" : "Merci d’avoir demandé une démonstration !",
        "contact" : "Nous allons prochainement vous contacter {0}.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "thanks" : "Merci,",
        "team" : "L’équipe Avery Dennison"
      },
      "avery" : {
        "intro" : "Quelqu’un a demandé une démonstration.",
        "contact" : "{0} a demandé à être contacté par {1} aau numéro/à l’adresse {2}.",
        "productInfo" : "Solution : {1} - {2}, {3} - {4}",
        "location" : "Location: {1}"
      }
    }
  },
  "zh" : {
    "request" : "请求演示",
    "copy" : "是，我同意接收关于产品和服务的信息，以及来自 艾利丹尼森的其他信息。",
    "form" : {
      "country" : "居住国家",
      "region" : "选择您的地区",
      "regions" : [
        [ "na", "北美洲" ],
        [ "eu", "欧洲" ],
        [ "me", "中东" ],
        [ "as", "亚洲" ],
        [ "aus", "澳大利亚" ],
        [ "other", "其他" ]
      ],
      "contact" : "首选联系方式",
      "email" : "电子邮件",
      "phone" : "电话",
      "submit" : "提交"
    },
    "error" : {
      "phone" : "电话号码无效",
      "country" : "居住国家称长度必须介于 1 到 50 个字符之间"
    },
    "thanks" : "感谢您请求演示！我们很快就会与您联系。",
    "emails" : {
      "user" : {
        "intro" : "感谢您请求演示！",
        "contact" : "很快就会有人通过电{0}与您联系.",
        "productInfo" : "{1} - {2}, {3} - {4}",
        "thanks" : "此致",
        "team" : "艾利丹尼森团队"
      },
      "avery" : {
        "intro" : "Someone has requested a demo.",
        "contact" : "{0} has requested someone contact them by {1} at {2}.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "location" : "Location: {1}"
      }
    }
  },
  "gr" : {
    "request" : "Demo anfordern",
    "copy" : "Ja, ich stimme zu, Informationen zu Produkten und Dienstleistungen sowie weitere Informationen von Avery Dennison zu erhalten.",
    "form" : {
      "country" : "Geben Sie Ihr Land oder Ihren US-Staat ein",
      "region" : "Wählen Sie Ihre Region aus",
      "regions" : [
        [ "na", "Nordamerika" ],
        [ "eu", "Europa" ],
        [ "me", "Naher Osten" ],
        [ "as", "Asien" ],
        [ "aus", "Australien" ],
        [ "other", "Andere" ]
      ],
      "contact" : "Kontakteinstellung",
      "email" : "E-Mail-Adresse",
      "phone" : "Telefon",
      "submit" : "Absenden"
    },
    "error" : {
      "phone" : "Die Telefonnummer ist nicht gültig",
      "country" : "Country must be between 1 and 50 characters long"
    },
    "thanks" : "Vielen Dank, dass Sie eine Demo angefordert haben! Wir werden bald mit Ihnen Kontakt aufnehmen.",
    "emails" : {
      "user" : {
        "intro" : "Vielen Dank, dass Sie eine Demo angefordert haben!",
        "contact" : "Jemand nimmt bald {0} mit Ihnen Kontakt auf.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "thanks" : "Vielen Dank.",
        "team" : "Das Avery Dennison-Team"
      },
      "avery" : {
        "intro" : "Someone has requested a demo.",
        "contact" : "{0} has requested someone contact them by {1} at {2}.",
        "productInfo" : "Solution: {1} - {2}, {3} - {4}",
        "location" : "Location: {1}"
      }
    }
  }
}
</i18n>