<template>
  <div class="modal_wrapper">
    <div class="modal_overlay" @click="close"></div>
    <div class="video_modal" :class="{fullscreen: fullscreen}">
      <button ref="close" class="close" type="button" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 28"> <g transform="translate(-1362 -46)"> <circle cx="14" cy="14" r="14" transform="translate(1362 46)" fill="#e31f26"/> <g transform="translate(0 10.632)"> <rect width="20" height="3" rx="1.5" transform="translate(1369.637 41.722) rotate(45)" fill="#fff"/> <rect width="20" height="3" rx="1.5" transform="translate(1367.869 55.51) rotate(-45)" fill="#fff"/> </g> </g> </svg> 
      </button>

      <div class="video_container">
        <vue-plyr ref="plyr" :options="i18nOptions">
          <video ref="video">
            <source v-if="windowWidth <= 426" :src="'/assets/media' + video + '_426.mp4'" type="video/mp4">
            <source v-else-if="windowWidth <= 640" :src="'/assets/media' + video + '_640.mp4'" type="video/mp4">
            <source v-else-if="windowWidth <= 960" :src="'/assets/media' + video + '_960.mp4'" type="video/mp4">
            <source v-else-if="windowWidth <= 1280" :src="'/assets/media' + video + '_1280.mp4'" type="video/mp4">
            <source v-else-if="windowWidth > 1280" :src="'/assets/media' + video + '_1920.mp4'" type="video/mp4">
            <track kind="captions" label="English" srclang="en" :src="'/assets/media' + video + '-en.vtt'" default>
            <track kind="captions" label="Français" srclang="fr" :src="'/assets/media' + video + '-fr.vtt'">
            <track kind="captions" label="中文" srclang="zh" :src="'/assets/media' + video + '-zh.vtt'">
            <track kind="captions" label="Deutsch" srclang="gr" :src="'/assets/media' + video + '-gr.vtt'">
          </video>
        </vue-plyr>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseanalytics } from '../firebase';

export default {
  name: 'VideoModal',
  components: {
  },
  props: {
    video: String
  },
  data(){
    return{
      windowHeight: 0,
      windowWidth: 0,
      fullscreen: false
    }
  },
  computed: {
    videoPlayback: function(){
      // how long video has been playing in seconds
      return this.$refs.video.currentTime
    },
    player: function() {
      return this.$refs.plyr.player
    },
    i18nOptions(){
      let options = {}
      if (this.$i18n.locale == 'fr') {
        options = { 
          i18n : {
            restart : "Redémarrer",
            rewind : "Revenir en arrière de {seektime} secondes",
            play : "Lire",
            pause : "Mettre en pause",
            fastForward : "Avancer rapidement de {seektime} secondes",
            seek : "Rechercher",
            played : "Lu",
            buffered : "Mis en mémoire tampon",
            currentTime : "Heure actuelle",
            duration : "Durée",
            volume : "Volume",
            mute : "Désactiver le son",
            unmute : "Réactiver le son",
            enableCaptions : "Activer les sous-titres",
            disableCaptions : "Désactiver les sous-titres",
            enterFullscreen : "Afficher en mode Plein écran",
            exitFullscreen : "Quitter le mode Plein écran",
            frameTitle : "Lecteur pour {title}",
            captions : "Sous-titres",
            settings : "Réglages",
            speed : "Vitesse",
            normal : "Normal",
            quality : "Qualité",
            loop : "Diffuser en boucle",
            start : "Début",
            end : "Fin",
            all : "Tout",
            reset : "Réinitialiser",
            disabled : "Désactivé",
            advertisement : "Annonce publicitaire",
          },
          controls: [
            'play-large', // The large play button in the center
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'fullscreen', // Toggle fullscreen
          ]
        }
      } else if (this.$i18n.locale == 'zh')  {
        options = { 
          i18n : {
            restart: '重新开始',
            rewind: '快退 {seektime} 秒',
            play: '播放',
            pause: '暂停',
            fastForward: '快进 {seektime} 秒',
            seek: '定位',
            played: '已播放',
            buffered: '已缓冲',
            currentTime: '当前时间',
            duration: '持续时间',
            volume: '音量',
            mute: '静音',
            unmute: '取消静音',
            enableCaptions: '启用字幕',
            disableCaptions: '禁用字幕',
            enterFullscreen: '进入全屏',
            exitFullscreen: '退出全屏',
            frameTitle: '{title} 的播放器',
            captions: '字幕',
            settings: '设置',
            speed: '速度',
            normal: '正常',
            quality: '画质',
            loop: '循环',
            start: '开始',
            end: '结束',
            all: '全部',
            reset: '重置',
            disabled: '已禁用',
            advertisement: '广告',
          },
          controls: [
            'play-large', // The large play button in the center
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'fullscreen', // Toggle fullscreen
          ]
        }
      } else if (this.$i18n.locale == 'gr')  {
        options = { 
          i18n : {
            restart: 'Erneut abspielen',
            rewind: 'Zurückspulen {seektime} Sek.',
            play: 'Abspielen',
            pause: 'Pause',
            fastForward: 'Vorspulen {seektime} Sek.',
            seek: 'Suchen',
            played: 'Abgespielt',
            buffered: 'Gebuffert',
            currentTime: 'Aktuelle Zeit',
            duration: 'Dauer',
            volume: 'Lautstärke',
            mute: 'Stumm stellen',
            unmute: 'Laut stellen',
            enableCaptions: 'Untertitel aktivieren',
            disableCaptions: 'Untertitel deaktivieren',
             enterFullscreen: 'Vollbildmodus aktivieren',
             exitFullscreen: 'Vollbildmodus deaktivieren',
            frameTitle: 'Player für {title}',
            captions: 'Untertitel',
            settings: 'Einstellungen',
            speed: 'Geschwindigkeit',
            normal: 'Normal',
            quality: 'Qualität',
            loop: 'Loop-Funktion',
            start: 'Start',
            end: 'Ende',
            all: 'Alles',
            reset: 'Zurücksetzen',
            disabled: 'Deaktiviert',
            advertisement: 'Hinzufügen',

          },
          controls: [
            'play-large', // The large play button in the center
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'fullscreen', // Toggle fullscreen
          ]
        }
      } else {
        options = { 
          controls: [
            'play-large', // The large play button in the center
            'play', // Play/pause playback
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'fullscreen', // Toggle fullscreen
          ]
        }
      }
      return options
    }
  },
  methods: {
    playVideo(){
      let video = this.$refs.video;
      this.player.currentTime = 0

      this.setVideoLanguage()

      setTimeout(function(){
        var playPromise = video.play();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
        }
      }, 500)
    },
    close(){
      let $this = this
      if (this.$store.getters.cookies == true) {
        firebaseanalytics.logEvent('video_watched', { video_id: $this.video, video_playback_time: $this.videoPlayback + ' sec'  })
      }

      this.$emit('closeModal');
    },
    enableEscape(event) {
      let $this = this
      if (event.key === 'Escape') {
        $this.$emit('closeModal');
      }
    },
    trapFocus(element) {
      var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
      var firstFocusableEl = focusableEls[0];  
      // var lastFocusableEl = focusableEls[focusableEls.length - 1];
      var lastFocusableEl = focusableEls[focusableEls.length - 2];
      var KEYCODE_TAB = 9;

      let $this = this

      element.addEventListener('keydown', function(e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) { 
          return; 
        }

        if ( e.shiftKey ) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
              e.preventDefault();
            }
          } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
              e.preventDefault();
            }
          }
      });
    },
    setVideoLanguage(){
      let lang = this.$i18n.locale
      if (lang != 'en') {
        this.player.captions.active = true;
      }
      this.player.captions.language = lang;
    },
    updateWindowSize: function(){
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    }
  },
  mounted(){
    // https://github.com/sampotts/plyr/blob/master/src/js/config/defaults.js
    window.addEventListener('resize', this.updateWindowSize)
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    this.playVideo()

    this.$refs.close.focus()
    this.trapFocus(this.$el)
    document.addEventListener('keydown', (event) => this.enableEscape(event))

    this.player.on('enterfullscreen', () => {
      this.fullscreen = true; 
    })
    this.player.on('exitfullscreen', () => {
      this.fullscreen = false; 
    })

    // set last active time to now
    var d = new Date();
    var newTime = d.getTime();
    localStorage.setItem('lastTime', newTime);
  },
  beforeDestroy(){
    document.removeEventListener('keydown', (event) => this.enableEscape(event))
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

$videoHeightDesktop : calc(100vh - 100px - 50px);
$videoWidthDesktop : calc(1.7778 * calc(100vh - 100px - 50px));

$videoWidthMobile : calc(100vw - 50px);
$videoHeightMobile : calc(.5625 * calc(100vw - 50px));

.video_modal {
  position: fixed;
  z-index: 99999;
  background-color: #fff;
  box-shadow: 2px -1px 4px rgba(0,0,0,0.06), 
              0 2px 2px rgba(0,0,0,0.06), 
              0 4px 4px rgba(0,0,0,0.06), 
              0 8px 8px rgba(0,0,0,0.06),
              0 16px 16px rgba(0,0,0,0.06);
  overflow-y: auto;
  display: flex;
  padding: 50px 0 0 0;

  width: $videoWidthMobile;
  height: calc(calc(.5625 * calc(100vw - 50px)) + 50px);
  left: 25px;
  top: calc(50vh - calc(calc(.5625 * calc(100vw - 50px)) + 50px) / 2);

  @media (min-aspect-ratio: 1188/742) {
    height: calc(100vh - 100px);
    top: 50px;
    bottom: 50px;

    width: $videoWidthDesktop;
    left: calc(50vw - (calc(1.778 * calc(100vh - 100px - 50px)) / 2));
  }

  .plyr {
    width: 100%;
  }

  &.fullscreen {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    width: 100vw;
    height: calc(100 * var(--vh));
    overflow: auto;
  }


  // @include screenLarge {
  //   // width: calc(100vw - 100px);
  //   height: $videoHeightDesktop;
  //   // left: 50px;
  //   top: 50px;
  //   bottom: 50px;

  //   width: $videoWidthDesktop;
  //   left: calc(50vw - (calc(1.778 * calc(100vh - 100px - 50px)) / 2));

  // }

  video {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .video_container {
    display: flex;
  }
}

.modal_overlay {
  position: fixed;
  overflow: auto;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}
</style>