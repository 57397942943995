<template>
  <div class="modal_wrapper">
    <div class="modal_overlay" @click="close"></div>

    <div class="modal" ref="modal" v-bind:class="{ 'modal_half': half, 'modal_auto': heightAuto, 'red_header': redheader, 'background_img' : backgroundImg }" v-bind:style="{ top: modalTop }">
      <button ref="close" class="close" type="button" @click="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 28"> <g transform="translate(-1362 -46)"> <circle cx="14" cy="14" r="14" transform="translate(1362 46)" fill="#e31f26"/> <g transform="translate(0 10.632)"> <rect width="20" height="3" rx="1.5" transform="translate(1369.637 41.722) rotate(45)" fill="#fff"/> <rect width="20" height="3" rx="1.5" transform="translate(1367.869 55.51) rotate(-45)" fill="#fff"/> </g> </g> </svg> </button>

      <div v-if="redheader" class="header text-center red_bg">
        <h3>{{header}}</h3>
      </div>
      <!-- <div class="modal_content"> -->
        <slot></slot>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  components: {
  },
  props: {
    width: null,
    height: null,
    header: null,
    backgroundImg: null
  },
  data(){
    return{
      modalHeight: 0
    }
  },
  computed: {
    redheader: function(){
      return this.header != null
    },
    half: function(){
      return this.width == 'half'
    },
    heightAuto: function(){
      return this.height == 'auto'
    },
    modalTop: function(){
      if (this.heightAuto) {
        return window.innerHeight / 2 - this.modalHeight / 2 + 'px'
      } 
    }
  },
  methods: {
    close(){
      this.$emit('closeModal');
    },
    updateModalHeight(){
      this.modalHeight = this.$refs.modal.clientHeight
    },
    enableEscape(event) {
      let $this = this
      if (event.key === 'Escape') {
        $this.$emit('closeModal');
      }
    },
    trapFocus(element) {
      var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
      var firstFocusableEl = focusableEls[0];  
      var lastFocusableEl = focusableEls[focusableEls.length - 1];
      var KEYCODE_TAB = 9;

      element.addEventListener('keydown', function(e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) { 
          return; 
        }

        if ( e.shiftKey ) /* shift + tab */ {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
              e.preventDefault();
            }
          } else /* tab */ {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
              e.preventDefault();
            }
          }
      });
    }
  },
  mounted(){
    // need to update this on resize
    this.$refs.close.focus()
    this.updateModalHeight()
    this.trapFocus(this.$el)
    document.addEventListener('keydown', (event) => this.enableEscape(event))

    // set last active time to now
    var d = new Date();
    var newTime = d.getTime();
    localStorage.setItem('lastTime', newTime);
  },
  beforeDestroy(){
    document.removeEventListener('keydown', (event) => this.enableEscape(event))
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_transitions.scss';
.modal {
  position: fixed;
  z-index: 99999;
  padding: 50px 0;
  background-color: #fff;
  box-shadow: 2px -1px 4px rgba(0,0,0,0.06), 
              0 2px 2px rgba(0,0,0,0.06), 
              0 4px 4px rgba(0,0,0,0.06), 
              0 8px 8px rgba(0,0,0,0.06),
              0 16px 16px rgba(0,0,0,0.06);

  width: calc(100vw - 50px);
  height: calc(calc(100 * var(--vh)) - 50px);
  left: 25px;
  top: 25px;
  bottom: 25px;
  overflow: auto;

  @include screenLarge {
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    left: 50px;
    top: 50px;
    bottom: 50px;
  }

  @include screenLargest {
    width: 1700px;
    left: calc((100vw - 1700px) / 2);
  }

  &.red_header {
    padding-top: 0;

    .red_bg {
      * {
        color: $colorWhite;
        padding: 15px;
        margin-bottom: 50px;
      }
    }
  }

  &_half {
    width: 50%;
    left: 25%;
    max-width:90%;

    width: clamp(360px, 50vw, 800px);
    left: clamp(calc(50vw - 400px), 25vw, calc(50vw - 180px));

    @media (max-width: 390px) {
      left: 5%;
    }
  }

  &_auto {
    height: auto;
    top: auto;
    bottom: auto;
  }

  &_overlay {
    position: fixed;
    overflow: auto;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
  }

  &_content {
    height: calc(calc(100 * var(--vh)) - 50px);
    overflow: auto;

    @include screenLarge {
      height: calc(100vh - 150px);
    }
  }

}
.modal_auto .modal_content {
  height: auto !important;
}
</style>