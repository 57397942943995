import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const configOptions = {
  apiKey: "AIzaSyCgLqxE1GekZSZ_poAxuK0neSui_i8fjmU",
  authDomain: "averydmx.firebaseapp.com",
  databaseURL: "https://averydmx.firebaseio.com",
  projectId: "averydmx",
  storageBucket: "averydmx.appspot.com",
  messagingSenderId: "908389722103",
  appId: "1:908389722103:web:b63bb1c07434f3ec22b0f2",
  measurementId: "G-2V2FS9FBEL"
};

firebase.initializeApp(configOptions);

const firebaseanalytics = firebase.analytics();

export { firebase, firebaseanalytics };
export default firebase;
