import { render, staticRenderFns } from "./StickyFooter.vue?vue&type=template&id=61e58af6&scoped=true&"
import script from "./StickyFooter.vue?vue&type=script&lang=js&"
export * from "./StickyFooter.vue?vue&type=script&lang=js&"
import style0 from "./StickyFooter.vue?vue&type=style&index=0&id=61e58af6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e58af6",
  null
  
)

/* custom blocks */
import block0 from "./StickyFooter.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports