<template>
  <div id="app" :class="[langClass, { 'logged_out': isLanding, 'red_header': (isIsometric || isRedHeader), 'header_padding' : isIsometric, 'webp': supportsWebp }]">
    
    <!-- navbar if not logged out home, password reset, okta login -->
    <Navbar @setTransition="setTransition" @resetTransition="resetTransition" v-if="!isLanding" v-bind:verticalMenu="vertical"></Navbar>

    <!-- <transition :name="transitionName" mode="out-in" :duration="transitionDuration"> -->
    <transition :name="transitionName" mode="out-in" :duration="transitionDuration">
      <router-view/>
    </transition>

    <!-- log out thank you -->
    <transition :name="stickyTransition">
      <Modal class="thankyoumodal_wrapper" v-if="showThankYou" @closeModal="showThankYou = false" width="half" height="auto"><ThankYou /></Modal>
    </transition>

    <!-- food lp modals called from multiple places -->
    <Modal v-if="showConnectedFood" @closeModal="showConnectedFood = false"><TruePartnership /></Modal>
    <Modal v-if="showResilienceFood" @closeModal="showResilienceFood = false"><Connected /></Modal>
    <Modal v-if="showSustainabilityFood" @closeModal="showSustainabilityFood = false"><FoodCircular /></Modal>

    <!-- retail lp modals called from multiple places -->
    <Modal v-if="showConnectedRetail" @closeModal="showConnectedRetail = false"><TruePartnership /></Modal>
    <Modal v-if="showResilienceRetail" @closeModal="showResilienceRetail = false"><Connected /></Modal>
    <Modal v-if="showSustainabilityRetail" @closeModal="showSustainabilityRetail = false"><RetailCircular /></Modal>

    <VideoModal v-bind:video="video" v-if="videoModalActive" @closeModal="videoModalActive = false"></VideoModal>

    <!-- sticky footer for food and retails pages/markets -->
    <transition :name="stickyTransition">
      <StickyFooter v-bind:verticalMenu="vertical" v-if="showSticky" v-bind:transition="stickyTransition"></StickyFooter>
    </transition>

    <!-- page to page animation -->
    <div class="overlay-top"></div>
    <div class="overlay-bottom"></div>

    <!-- IE Detected Modal -->
    <Modal v-if="showIEModal" @closeModal="showIEModal = false" width="half" height="auto">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center">
            <h2 class="red mb-0">{{ $t('ieCopy') }}</h2>
          </div>
        </div>
      </div>
    </Modal>

    <CookieBanner v-if="cookies == null && $route.name != 'Home'" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import StickyFooter from "@/components/StickyFooter";
import Modal from "@/components/Modal.vue";
import VideoModal from "@/components/VideoModal.vue";
import CookieBanner from "@/components/CookieBanner.vue";

import TruePartnership from '@/modals/TruePartnership';
import Connected from '@/modals/Connected';
import FoodCircular from '@/modals/FoodCircular.vue';
import RetailCircular from '@/modals/RetailCircular.vue';

import ThankYou from '@/modals/ThankYou.vue';

import { mapGetters } from "vuex";
import { firebase, firebaseanalytics } from "@/firebase";

export default {
  name: 'App',
  components: {
    Navbar,
    StickyFooter,
    Modal,
    VideoModal,
    CookieBanner,
    TruePartnership,
    Connected,
    FoodCircular,
    RetailCircular,
    ThankYou
  },
  data(){
    return {
      showThankYou: false,
      showLoggedOut: false,
      isRedHeader: false,

      showConnectedFood: false,
      showResilienceFood: false,
      showSustainabilityFood: false,

      showConnectedRetail: false,
      showResilienceRetail: false,
      showSustainabilityRetail: false,

      supportsWebp: true,

      videoModalActive: false,
      video: null,

      transitionName: 'overlay-down-full',
      transitionDuration: 250,
      stickyTransition: 'fade-delay',

      windowWidth: null,
      connection: 'slow',
      showIEModal: false
    }
  },
  computed: {
    ...mapGetters({
      language: "locale",
      cookies: "cookies"
    }),
    langClass: function(){
      return this.$i18n.locale
    },
    showSticky: function(){
      if (this.$route.meta.vertical) { 
        return true; 
      } else {
        return false;
      }
    },
    vertical: function(){
      if (this.$route.meta.vertical == 'food') { 
        return 'food'; 
      } else if (this.$route.meta.vertical == 'retail'){
        return 'retail';
      }
    },
    isLanding: function(){
      return this.$route.name == "Home" && !this.user.loggedIn;
    },
    isIsometric: function(){
      return this.$route.meta.header == 'red'
    },
    isIE: function(){
      return window.document.documentMode;
    }
  },
  methods: {
    updateVH: function(){
      if (this.windowWidth < 1024 && this.windowWidth == window.innerWidth) {
        // this is tablet or mobile
        // and resize is only being triggered by searchbar show/hide
      } else {
        this.windowWidth = window.innerWidth
        document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
      }
    },
    setTransition: function(){
      this.transitionName = ''
      this.transitionDuration = 0

      this.stickyTransition = ''

      this.$el.classList.add('transition_none')
    },
    resetTransition: function(){
      this.transitionName = 'overlay-down-full';
      this.transitionDuration = 250

      let $this = this
      setTimeout(function(){
        $this.stickyTransition = 'fade-delay'
        $this.$el.classList.remove('transition_none')
      }, 500)
      
    },
    allowBackgroundVideos: function(){
      let $this = this
      if (navigator.connection == undefined) {
        if (window.innerWidth > 767) {
          $this.connection = "fast";
        } else {
          $this.connection = "slow"
        }
      } else if (/\slow-2g|2g|3g/.test(navigator.connection.effectiveType)) {
        $this.connection = "slow";
      } else {
        $this.connection = "fast";
      }
    }
  },
  async mounted() {

    async function supportsWebp() {
      if (!self.createImageBitmap) return false;
      
      const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
      const blob = await fetch(webpData).then(r => r.blob());
      return createImageBitmap(blob).then(() => true, () => false);
    }

    (async () => {
      if(await supportsWebp()) {
        this.supportsWebp = true
      }
      else {
        this.supportsWebp = false
      }
    })();

    if (this.isIE) {
      this.showIEModal = true;
    }

    this.allowBackgroundVideos()

    this.windowWidth = window.innerWidth
    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', this.updateVH)


    // when mounted, set cookie prefs
    let cookies = this.cookies;
    firebaseanalytics.setAnalyticsCollectionEnabled(cookies)
  },
  watch: {
    '$route' (to, from) {
      let $this = this
      // update vh on route change
      // because on mobile, search bar can be shown or hidden
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_transitions.scss';

@import '@/assets/scss/_video.scss';
@import '@/assets/scss/main.scss';

body {
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#app {
  font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  transition: padding-top 0.5s;
  transition-delay: 0.25s;

  &.transition_none {
    transition-delay: 0s;
    // transition-duration: 0s;
  }

  .sticky_footer {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
    }
  }

  &.header_padding {
    padding-top: 68px;
  }

  .plyr--full-ui input[type=range] {
    color: $colorRed;
  }
  .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
    background: $colorRed;
  }
  .plyr--video .plyr__control--overlaid {
    background: rgba($colorWhite, 0.8);
    color: $colorRed;
    &:hover {
      background: $colorWhite;
    }
  }

  .plyr__caption {
    color: $colorWhite;
  }
  // .plyr--video{overflow:auto!important;} 
  .plyr__poster{background-size:cover!important;}
}

.page-fade-enter-active, .page-fade-leave-active {
  transition: all 0.25s;
}
.page-fade-enter, .page-fade-leave-to {
  opacity: 0;
}

.thankyoumodal_wrapper {
  .modal_overlay, .modal {
    z-index: 997;
  }
}

.overlay-top {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  max-width: 100%;
  background: $colorRed;
  transition-duration: 500ms;
  z-index: 998;
}
.overlay-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100vw;
  max-width: 100%;
  background: $colorRed;
  transition-duration: 500ms;
  z-index: 998;
}

.overlay-up-full-enter ~ .overlay-bottom,
.overlay-up-full-enter-active ~ .overlay-bottom,
.overlay-up-full-leave-active ~ .overlay-bottom {
  height: 100vh;
}
.overlay-up-full-enter-active ~ .overlay-bottom {
  transition-timing-function: ease-in;
}
.overlay-up-full-enter-active ~ .overlay-bottom,
.overlay-up-full-enter-to ~ .overlay-bottom {
  display: none;
}
.overlay-up-full-enter-to ~ .overlay-top {
  height: 0;
}
.overlay-up-full-leave-active ~ .overlay-top {
  transition-timing-function: ease;
}
.overlay-up-full-enter ~ .overlay-top,
.overlay-up-full-enter-active ~ .overlay-top,
.overlay-up-full-enter-to ~ .overlay-top {
  transition-duration: unset !important;
  height: 100vh;
}
.overlay-up-full-enter-active,
.overlay-up-full-leave-active {
  transition-duration: 500ms;
}
//
.active ~ .overlay-top,
.active ~ .overlay-bottom {
  opacity: 0 !important;
}

.overlay-down-full-enter ~ .overlay-top,
.overlay-down-full-enter-to ~ .overlay-bottom {
  height: 0;
}
.overlay-down-full-enter-active ~ .overlay-top,
.overlay-down-full-leave-active ~ .overlay-top {
  height: 100vh;
}
.overlay-down-full-enter-active ~ .overlay-top {
  transition-timing-function: ease-in;
}
.overlay-down-full-leave-active ~ .overlay-bottom {
  transition-timing-function: ease;
}
.overlay-down-full-enter-active,
.overlay-down-full-leave-active {
  transition-duration: 350ms;
}
.overlay-down-full-enter-active ~ .overlay-top,
.overlay-down-full-enter-to ~ .overlay-top {
  display: none;
}
.overlay-down-full-enter ~ .overlay-bottom,
.overlay-down-full-enter-active ~ .overlay-bottom,
.overlay-down-full-enter-to ~ .overlay-bottom {
  transition-duration: unset !important;
  height: 100vh;
}

.modal h2.red.mb-0 { margin-bottom: 0; }
</style>

<i18n>
{
  "en" : {
    "ieCopy" : "This site is not supported in Internet Explorer. Please use Microsoft Edge, Google Chrome, Firefox, or Safari for a better experience."
  },
  "fr" : {
    "ieCopy" : "Ce site n’est pas pris en charge par Internet Explorer. Veuillez utiliser Microsoft Edge, Google Chrome, Firefox, ou Safari pour une meilleure expérience."
  },
  "zh" : {
    "ieCopy" : "此网站在 Internet Explorer 中不受支持。请使用 Microsoft Edge、Google Chrome、Firefox 或 Safari，以获得更佳体验。"
  }
}
</i18n>