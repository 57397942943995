import Vue from 'vue'
import Router from 'vue-router'
import SecondHome from '../views/SecondHome.vue'
import store from '@/store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'SecondHome',
    component: SecondHome,
    meta: {
      title: 'Avery Dennison | Home',
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "utilities" */ '../views/Contact.vue'),
    meta: {
      title: 'Avery Dennison | Contact',
      header: 'red'
    }
  },
  {
    path: '/food',
    name: 'Food',
    component: () => import(/* webpackChunkName: "food" */ '../views/Food.vue'),
    meta: {
      title: 'Avery Dennison | Food Supply Chain',
      vertical: 'food'
    }
  },
  {
    path: '/food/grower',
    name: 'Grower',
    component: () => import(/* webpackChunkName: "food" */ '../views/Grower.vue'),
    meta: {
      title: 'Avery Dennison | Grower',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/processor',
    name: 'Processor',
    component: () => import(/* webpackChunkName: "food" */ '../views/Processor.vue'),
    meta: {
      title: 'Avery Dennison | Processor',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/dc',
    name: 'DC',
    component: () => import(/* webpackChunkName: "food" */ '../views/DC.vue'),
    meta: {
      title: 'Avery Dennison | Distribution',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/stockroom',
    name: 'BOH',
    component: () => import(/* webpackChunkName: "food" */ '../views/BOH.vue'),
    meta: {
      title: 'Avery Dennison | Stockroom',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/kitchen',
    name: 'Kitchen',
    component: () => import(/* webpackChunkName: "food" */ '../views/Kitchen.vue'),
    meta: {
      title: 'Avery Dennison | Kitchen',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/restaurant',
    name: 'Restaurant',
    component: () => import(/* webpackChunkName: "food" */ '../views/Restaurant.vue'),
    meta: {
      title: 'Avery Dennison | Restaurant',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/food/grocery',
    name: 'Grocery',
    component: () => import(/* webpackChunkName: "food" */ '../views/Grocery.vue'),
    meta: {
      title: 'Avery Dennison | Grocery',
      vertical: 'food',
      header: 'red'
    }
  },
  {
    path: '/retail',
    name: 'Retail',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Retail.vue'),
    meta: {
      title: 'Avery Dennison | Retail Supply Chain',
      vertical: 'retail'
    }
  },
  {
    path: '/retail/manufacturing',
    name: 'Manufacturing',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Manufacturing.vue'),
    meta: {
      title: 'Avery Dennison | Manufacturing',
      vertical: 'retail',
      header: 'red'
    }
  },
  {
    path: '/retail/first-mile',
    name: 'FirstMile',
    component: () => import(/* webpackChunkName: "retail" */ '../views/FirstMile.vue'),
    meta: {
      title: 'Avery Dennison | First Mile',
      vertical: 'retail'
    }
  },
  {
    path: '/retail/distribution',
    name: 'Distribution',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Distribution.vue'),
    meta: {
      title: 'Avery Dennison | Distribution',
      vertical: 'retail',
      header: 'red'
    }
  },
  {
    path: '/retail/fulfillment',
    name: 'Fulfillment',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Fulfillment.vue'),
    meta: {
      title: 'Avery Dennison | Fulfillment',
      vertical: 'retail'
    }
  },
  {
    path: '/retail/last-mile',
    name: 'LastMile',
    component: () => import(/* webpackChunkName: "retail" */ '../views/LastMile.vue'),
    meta: {
      title: 'Avery Dennison | Last Mile',
      vertical: 'retail',
      header: 'red'
    }
  },
  {
    path: '/retail/store',
    name: 'Store',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Store.vue'),
    meta: {
      title: 'Avery Dennison | Store',
      vertical: 'retail',
      header: 'red'
    }
  },
  {
    path: '/retail/destination',
    name: 'Final',
    component: () => import(/* webpackChunkName: "retail" */ '../views/Final.vue'),
    meta: {
      title: 'Avery Dennison | Final Destination',
      vertical: 'retail',
      header: 'red'
    }
  },
  {
    path: '/connected-supply-chain',
    name: 'Connected',
    component: () => import(/* webpackChunkName: "connected" */ '../views/Freshmarx.vue'),
    meta: {
      title: 'Avery Dennison | Connected Supply Chain',
      header: 'red'
    }
  },
  // {
  //   path: '/downloads',
  //   name: 'Downloads',
  //   component: () => import(/* webpackChunkName: "utilities" */ '../views/Downloads.vue'),
  //   meta: {
  //     title: 'Avery Dennison | Multilingual Downloads',
  //     header: 'red'
  //   }
  // },
  {
    path: '/cookies',
    name: 'CookiePolicy',
    component: () => import(/* webpackChunkName: "policies" */ '../views/Cookies.vue'),
    meta: {
      title: 'Avery Dennison | Cookie Policy',
      header: 'red'
    }
  },
  {
    path: '/privacy',
    name: 'LegalPrivacy',
    component: () => import(/* webpackChunkName: "policies" */ '../views/Legal.vue'),
    meta: {
      title: 'Avery Dennison | Legal & Privacy Notices',
      header: 'red'
    }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "fourohfour" */ '../views/404.vue'),
    meta: {
      title: 'Avery Dennison | 404',
      header: 'red'
    }
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {

    if (savedPosition && to.path != '/home') {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // set page title
  // let title = to.meta.title
  // document.title = title

 // console.log(to)

  if (to.query.lang) {
    switch (to.query.lang) {
      case 'en':
      case 'fr':
      case 'zh':
      case 'gr':
        store.dispatch("setLanguage", to.query.lang);
        break;

      default:
        return;
    }
    let new_query = to.query;
    delete new_query.lang;
    router.replace( { path: to.path, query: new_query } );
  }
  next();

})

// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;

//     if (route.path !== '*') {
//       array.push(path);
//     }

//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }

//     return array;
//   }, []);
// }


//         function getRoutesXML() {
//   const list = getRoutesList(router.options.routes, 'https://supplychain.averydennison.com')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }

// console.log(getRoutesXML())
export default router