<template>
  <div class="cookie_banner">
    <p><strong>{{$t('usesCookies')}}</strong> <br><a href="#" @click.prevent="cookiePopup = true">{{$t('view')}}</a></p>
    <button class="btn-link" type="button" @click="updateCookies(true)">{{$t('accept')}}</button>
    <button class="btn-link" type="button" @click="updateCookies(false)">{{$t('decline')}}</button>

    <Modal v-if="cookiePopup" @closeModal="cookiePopup = false">
      <div class="container-fluid terms_popup">
        <div class="row">
          <div class="col-12">
            <div v-if="userLanguage == 'en'">
              <h1>Cookie Policy</h1>
              <p><strong>Last updated: May 24, 2018</strong></p>

              <p>This Cookie Statement explains how Avery Dennison and its group companies collectively (“Avery Dennison”, "we", "us", and "ours") use cookies and similar technologies to recognise you when you visit our websites.  It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

              <h3>What are cookies?</h3>
              <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website.  Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.  </p>

              <p>Cookies set by the website owner (in this case, Avery Dennison) are called "first party cookies".  Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics).  The parties that set these third party cookies can recognise your computer both when it visits the website in question and also when it visits certain other websites.</p>

              <h3>Why do we use cookies?</h3>
              <p>We use first party and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Websites.   Third parties serve cookies through our Websites for advertising purposes. This is described in more detail below.</p>

              <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described in the table below (please note that the specific cookies served may vary depending on the specific Website you visit):</p>

              <table>
                <thead>
                  <tr>
                    <th>Types of cookie</th>
                    <th>Who serves these cookies</th>
                    <th>How to refuse</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Essential website cookies:</strong> These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</td>
                    <td>Avery Dennison</td>
                    <td>Because these cookies are strictly necessary to deliver the Websites to you, you cannot refuse them.</td>
                  </tr>
                  <tr>
                    <td><strong>Performance and functionality cookies:</strong> These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use.  However, without these cookies, certain functionality may become unavailable.</td>
                    <td>Avery Dennison</td>
                    <td>To refuse these cookies, please follow the instructions below under the heading "How can I control cookies?"</td>
                  </tr>
                  <tr>
                    <td><strong>Advertising cookies:</strong> These cookies are used to make advertising messages more relevant to you.  They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.</td>
                    <td>Avery Dennison</td>
                    <td>To refuse these cookies, please follow the instructions below under the heading "How can I control cookies?"</td>
                  </tr>
                </tbody>
              </table>

              <h3>What about other tracking technologies, like web beacons?</h3>
              <p>Cookies are not the only way to recognise or track visitors to a website.  We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs").  These are tiny graphics files that contain a unique identifier that enable us to recognise when someone has visited our Websites. This allows us, for example, to monitor the traffic patterns of users from one page within our Websites to another, to deliver or communicate with cookies, to understand whether you have come to our Websites from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns.  In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>

              <h3>Do you serve targeted advertising?</h3>
              <p>Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other personally identifying details unless you choose to provide these.</p>

              <h3>How can I control cookies?</h3>
              <p>You have the right to decide whether to accept or reject cookies.  You can exercise your cookie preferences by clicking on the appropriate opt-out links provided in the cookie table above.</p>

              <p>You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.   As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</p>

              <p>In addition, most advertising networks offer you a way to opt out of targeted advertising.  If you would like to find out more information, please visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.</p>

              <h3>How often will you update this Cookie Statement?</h3>
              <p>We may update this Cookie Statement from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons.  Please therefore re-visit this Cookie Statement regularly to stay informed about our use of cookies and related technologies.</p>

              <p>The date at the top of this Cookie Statement indicates when it was last updated.  </p>

              <h3>Where can I get further information?</h3>
              <p>If you have any questions about our use of cookies or other technologies, please email us at GDPR.info@averydennison.com</p>
            </div>

            <div v-if="userLanguage == 'fr'">
              <h1>Politique en matière de Cookies</h1>
              <p><strong>Dernière mise à jour : 24 mai 2018</strong></p>

              <p>Cette déclaration relative aux cookies explique comment Avery Dennison et les entreprises de son groupe (collectivement dénommés « Avery Dennison », « nous », « notre » et « nos ») utilisent des cookies et des technologies similaires afin de vous reconnaître lorsque vous consultez nos sites web.  Elle explique quelles sont ces technologies et pourquoi nous les utilisons, ainsi que vos droits de contrôle de notre utilisation de celles-ci.</p>

              <h3>Que sont les cookies ?</h3>
              <p>Les cookies sont de petits fichiers de données placés sur votre ordinateur ou votre appareil mobile lorsque vous consultez un site web.  Les cookies sont fréquemment utilisés par les propriétaires de site web afin de faire fonctionner leurs sites web ou de les faire fonctionner plus efficacement, ainsi que fournir des rapports d’information. </p>

              <p>Les cookies définis par le propriétaire du site web (ici, Avery Dennison) sont appelés « cookies internes ».  Les cookies définis par des parties autres que le propriétaire du site web sont appelés « cookies tierce partie ». Les cookies tierce partie permettent de fournir des fonctions ou fonctionnalités de tiers sur le site web ou par l’intermédiaire de celui-ci (p. ex., publicité, contenu interactif et analyses).  Les parties qui définissent ces cookies tierce partie peuvent reconnaître votre ordinateur lorsqu’il consulte le site web en question et également lorsqu’il en consulte certains autres.</p>

              <h3>Pourquoi utilisons-nous des cookies ?</h3>
              <p>Nous utilisons des cookies internes et des cookies tierce partie pour plusieurs raisons. Certains cookies sont nécessaires pour des raisons techniques afin que nos sites web puissent fonctionner. Nous les appelons les cookies « essentiels » ou « absolument nécessaires ». D’autres cookies nous permettent également de suivre et de cibler les intérêts de nos utilisateurs afin d’améliorer l’expérience de nos sites web.  Les tiers fournissent des cookies sur nos sites web à des fins publicitaires. Ceci est décrit plus en détail ci-dessous.</p>

              <p>Les types spécifiques cookies internes et tierce partie fournis sur nos sites web et les rôles qu’ils remplissent sont décrits dans le tableau ci-dessous (veuillez noter que les cookies spécifiques fournis peuvent varier en fonction du site web que vous consultez) :</p>

              <table>
                <thead>
                  <tr>
                    <th>Types de cookies</th>
                    <th>Entité fournissant ces cookies</th>
                    <th>Comment refuser</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Cookies essentiels du site web :</strong> ces cookies sont absolument nécessaires afin de vous fournir les services disponibles sur nos sites web et d’utiliser certaines de ses fonctionnalités, telles que l’accès à des zones sécurisées.</td>
                    <td>Avery Dennison</td>
                    <td>Ces cookies étant absolument nécessaires afin de vous proposer les sites web, vous ne pouvez pas les refuser.</td>
                  </tr>
                  <tr>
                    <td><strong>Cookies de performances et de fonctionnalité :</strong> ces cookies sont utilisés pour améliorer les performances et la fonctionnalité de nos sites web, mais ne sont pas essentiels à leur utilisation.  Toutefois, sans ces cookies, certaines fonctionnalités peuvent devenir indisponibles.</td>
                    <td>Avery Dennison</td>
                    <td>Pour refuser ces cookies, veuillez suivre les instructions ci-dessous dans la rubrique « Comment puis-je contrôler les cookies ? »</td>
                  </tr>
                  <tr>
                    <td><strong>Cookies publicitaires :</strong> ces cookies sont utilisés afin de rendre les messages publicitaires plus pertinents pour vous.  Ils remplissent des fonctions comme celle d’empêcher la réapparition perpétuelle de la même publicité, en veillant à ce que les publicités soient affichées correctement pour les publicitaires, et dans certains cas, en sélectionnant des publicités qui sont basées sur vos centres d’intérêt.</td>
                    <td>Avery Dennison</td>
                    <td>Pour refuser ces cookies, veuillez suivre les instructions ci-dessous dans la rubrique « Comment puis-je contrôler les cookies ? »</td>
                  </tr>
                </tbody>
              </table>

              <h3>Qu’en est-il des autres technologies de suivi, telles que les balises web ?</h3>
              <p>Les cookies ne sont pas la seule façon de reconnaître ou de suivre les visiteurs d’un site web.  Nous pouvons utiliser d’autres technologies similaires de temps à autre, comme des balises web (parfois appelées « pixels-espions » ou « pixels invisibles »).  Ce sont de minuscules fichiers graphiques contenant un identifiant unique qui nous permet de reconnaître les moments où quelqu’un a consulté notre site web. Cela nous permet, par exemple, de surveiller les habitudes de navigation des utilisateurs d’une page de sites web à l’autre, de distribuer les cookies ou de communiquer avec eux, de comprendre si vous êtes arrivé sur nos sites web à partir d’une publicité affichée sur un site web tiers, d’améliorer les performances du site et de mesurer le succès des campagnes d’e-mail marketing.  Dans de nombreux cas, ces technologies reposent sur des cookies pour fonctionner correctement. Refuser des cookies nuira donc à leur fonctionnement.</p>

              <h3>Fournissez-vous des publicités ciblées ?</h3>
              <p>Des tiers peuvent mettre à disposition des cookies sur votre ordinateur ou appareil mobile pour fournir de la publicité par le biais de nos Sites web. Ces entreprises peuvent exploiter les informations relatives à vos consultations de ce site web et d’autres afin de fournir des publicités pertinentes relatives à des biens et services susceptibles de vous intéresser. Ils peuvent également utiliser une technologie employée pour mesurer l’efficacité de la publicité. Ils peuvent le faire à l’aide des cookies ou des balises web afin de collecter des informations sur vos consultations de ce site et d’autres afin de fournir des publicités pertinentes relatives à des biens et des services pouvant potentiellement vous intéresser. Les informations collectées par le biais de ce processus ne nous permettent pas et ne leur permettent pas d’identifier votre nom, vos coordonnées ou d’autres informations permettant de vous identifier personnellement sauf si vous choisissez de les fournir.</p>

              <h3>Comment puis-je contrôler les cookies ?</h3>
              <p>Vous avez le droit de décider d’accepter ou de refuser les cookies.  Vous pouvez exercer vos préférences en matière de cookies en cliquant sur les liens de désinscription appropriés fournis dans le tableau des cookies ci-dessus.</p>

              <p>Vous pouvez définir ou modifier les commandes de votre navigateur Internet pour accepter ou refuser les cookies. Si vous choisissez de refuser les cookies, vous pouvez toujours utiliser notre site web, même si votre accès à certaines fonctionnalités et zones de notre site web peut être restreint.   Les moyens par lesquels vous pouvez refuser les cookies par l’intermédiaire des commandes de votre navigateur Internet pouvant varier d’un navigateur à l’autre, consultez le menu Aide de votre navigateur pour plus d’informations.</p>

              <p>Par ailleurs, la plupart des réseaux de publicité vous offrent un moyen vous désinscrire de la publicité ciblée.  Si vous souhaitez obtenir plus d’information, veuillez consulter les sites http://www.aboutads.info/choices/ ou http://www.youronlinechoices.com.</p>

              <h3>À quelle fréquence mettrez-vous à jour cette déclaration relative aux cookies ?</h3>
              <p>Nous pouvons mettre à jour cette déclaration relative aux cookies de temps à autre afin de refléter, par exemple, les modifications apportées aux cookies que nous utilisons ou pour d’autres raisons opérationnelles, juridiques ou réglementaires.  Veuillez donc relire cette déclaration relative aux cookies régulièrement pour rester informé de notre utilisation des cookies et des technologies connexes.</p>

              <p>La date en haut de cette déclaration relative aux cookies indique la date de sa dernière mise à jour. </p>

              <h3>Où puis-je obtenir plus d’informations ?</h3>
              <p>Si vous avez des questions au sujet de notre utilisation des cookies ou d’autres technologies, veuillez nous envoyer un e-mail à l’adresse GDPR.info@averydennison.com</p>
            </div>

            <div v-if="userLanguage == 'zh'">
              <h1>Cookie 政策</h1>
              <p><strong>最后更新日期： 2018-5-24</strong></p>
              <p>此 Cookie 声明解释艾利丹尼森及其集团公司（统称为“艾利丹尼森”、“我们（主语）”、“我们（宾语）”和“我们的”）使用 Cookie 和类似技术识别您访问网站的时间。  它解释了这些技术是什么、我们使用这些技术的原因以及您控制使用这些技术的权利。</p>
              <h3>什么是 Cookie？</h3>
              <p>Cookie 是当您访问网站时在您的计算机或移动设备上设置的小数据文件。  Cookie 被网站所有者广泛使用，以便从事网站工作、更高效工作以及提供报告信息。</p>
              <p>网站所有者（在此案例中是艾利丹尼森）设置的 Cookie 称为“第一方 Cookie”。  网站所有者以外的其他各方设置的 Cookie 称为“第三方 Cookie”。 第三方 Cookie 使得能够在网站上或者通过网站提供第三方特点或功能（比如广告、交互内容和分析）。  设置这些第三方 Cookie 的各方可以在访问相关网站和某些其他网站时识别您的计算机。</p>
              <h3>我们为何使用 Cookie？</h3>
              <p>我们出于多种原因使用第一方 Cookie 和第三方 Cookie。 需要某些 Cookie 是出于技术原因，以便我们的网站正常运行，我们将这些 Cookie 称为“必不可少”或“绝对必要”的 Cookie。 其他 Cookie 也使我们能够跟踪和锁定用户的兴趣，从而增强在我们的网站上的体验。   第三方通过我们的网站出于广告目的而提供 Cookie。 下面更详细描述了这方面的内容。</p>
              <p>下面的表格描述了通过我们的网站提供的第一方和第三方 Cookie 的特定类型以及执行 Cookie 的目的（请注意，根据您访问的特定网站，提供的特定 Cookie 可能不同）：</p>

              <table>
                <thead>
                  <tr>
                    <td>Cookie 的类型</td>
                    <td>何人提供这些 Cookie</td>
                    <td>如何拒绝</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>必不可少的网站  Cookie： 这些 Cookie 是绝对必要的，可以为您提供通过网站可以获取的服务和使用它的某些功能，例如访问安全区域</td>
                    <td>艾利丹尼森</td>
                    <td>由于这些 Cookie 对于为您提供网站是绝对必要的，因此您不能拒绝这些 Cookie。</td>
                  </tr>
                  <tr>
                    <td>性能和功能  Cookie： 这些 Cookie 用于增强我们网站的性能和功能，但对于使用并非必不可少。  但是，如果没有这些 Cookie，某些功能可能不可用。</td>
                    <td>艾利丹尼森</td>
                    <td>要拒绝这些 Cookie，请遵循下面的“如何控制 Cookie？”标题部分下的说明。</td>
                  </tr>
                  <tr>
                    <td>广告 Cookie： 这些 Cookie 用于提供与您更相关的广告信息。  它们执行的功能有防止相同广告或持续重复出现广告、确保为广告商正确显示广告，并且在某些情况下，选择基于您的兴趣的广告。</td>
                    <td>艾利丹尼森</td>
                    <td>要拒绝这些 Cookie，请遵循下面的“如何控制 Cookie？”标题部分下的说明。</td>
                  </tr>
                </tbody>
              </table>

              <h3>其他跟踪技术（比如 Web 信标）的情况如何？</h3>
              <p>Cookie 并非是识别或跟踪网站访客的唯一方法。  我们可能随时使用其他类似技术，比如 Web 信标（有时称为“跟踪像素”或“网络信标”）。  这些小图形文件包含唯一识别符，使我们能够识别某人访问我们网站的时间。 比如，这使我们能够监控用户从网站的一个页面到另一个页面的流量模式、提供 Cookie 或者与之通信、了解您是否从第三方网站上显示的在线广告访问我们的网站、提高站点性能以及衡量电子邮件营销活动是否取得成效。  在许多实例中，这些技术依赖于 Cookie 才能正常工作，因此拒绝 Cookie 将会影响其功能。</p>
              <h3>您是否提供针对性广告？</h3>
              <p>第三方可能在您的计算机或移动设备上提供 Cookie，以便通过网站提供广告。 这些公司可能使用有关您访问此网站和其他网站的信息，提供您可能感兴趣的商品和服务的相关广告。 他们也可能采用用于衡量广告有效性的技术。 他们可以使用 Cookie 或 Web 信标来收集有关您访问此站点和其他站点的信息，从而达到衡量广告有效性的目的，以便提供您潜在感兴趣的商品和服务的相关广告。 通过此过程收集的信息并不能使我们或他们识别您的姓名、详细联系信息或其他个人识别详细信息，除非您选择提供这些信息。</p>
              <h3>如何控制 Cookie？</h3>
              <p>您有权利决定是接受还是拒绝 Cookie。  您可以通过单击上面的 Cookie 表格中提供的相应的“拒绝接受”链接来选择 Cookie 偏好。</p>
              <p>您可以设置或修改 Web 浏览器控件以接受或拒绝 Cookie。 如果选择拒绝  Cookie，您仍然可以使用网站，尽管访问网站的某些功能和区域可能受到限制。   由于您通过 Web 浏览器控件拒绝 Cookie 的方式因浏览器而变化，您应访问浏览器的帮助菜单了解更多信息。</p>
              <p>此外，大多数广告网络为您提供拒绝接受针对性广告的方法。  如果您想了解更多信息，请访问 http://www.aboutads.info/choices/ 或 http://www.youronlinechoices.com。</p>
              <h3>更新此 Cookie 声明的频率如何？</h3>
              <p>我们可能随时更新此 Cookie 声明，以便反映我们使用的 Cookie 的更改，或者出于其他运营、法律或监管原因等。  因此，请定期重新访问此 Cookie 声明，及时了解有关我们使用 Cookie 和相关技术的信息。</p>
              <p>此 Cookie 声明顶部的日期指明上次更新的时间。</p>
              <h3>我可以从何处获取其他信息？</h3>
              <p>如果对使用 Cookie 或其他技术存有任何问题，请向我们发送电子邮件，电子信箱：GDPR.info@averydennison.com</p>
            </div>

            <div v-if="userLanguage == 'gr'">
              <h1>Cookie-Richtlinie</h1>
              <p><strong>Letzte Aktualisierung: 24. Mai 2018</strong></p>

              <p>Diese Cookie-Erklärung erläutert, wie Avery Dennison und seine Konzernunternehmen (gemeinsam „Avery Dennison“, „wir“, „uns“ und „unsere“ genannt) Cookies und ähnliche Technologien verwenden, um Sie beim Besuch unserer Websites zu erkennen.  Sie erklärt, was diese Technologien sind und warum wir sie verwenden, sowie Ihre Rechte, um deren Verwendung zu kontrollieren.</p>

              <h3>Was sind Cookies?</h3>

              <p>Cookies sind kleine Textdateien, die beim Besuch einer Website auf Ihrem Computer oder mobilen Gerät gespeichert werden.  Cookies werden häufig von den Betreibern von Websites verwendet, um ihre Websites funktionsfähig zu machen oder effizienter zu gestalten, sowie um Berichtsinformationen bereitzustellen. </p>

              <p>Cookies, die vom Besitzer der Website (in diesem Fall Avery Dennison) gesetzt werden, heißen „Erstanbieter-Cookies“.  Cookies, die von anderen Parteien als dem Eigentümer der Website gesetzt werden, werden „Drittanbieter-Cookies“ genannt. Drittanbieter-Cookies ermöglichen die Bereitstellung von Funktionen oder Funktionalitäten Dritter auf oder über die Website (z. B. Werbung, interaktive Inhalte und Analysen).  Die Parteien, die diese Drittanbieter-Cookies setzen, können Ihren Computer sowohl beim Besuch der betreffenden Website als auch beim Besuch bestimmter anderer Websites wiedererkennen.</p>

              <h3>Warum verwenden wir Cookies?</h3>

              <p>Wir verwenden aus verschiedenen Gründen Erstanbieter- und Drittanbieter-Cookies. Einige Cookies werden aus technischen Gründen benötigt, damit unsere Websites funktionieren können. Wir bezeichnen diese als „essentielle“ oder „unbedingt notwendige“ Cookies. Andere Cookies ermöglichen es uns auch, die Interessen unserer Nutzer zu verfolgen und gezielt anzusprechen, um das Erlebnis auf unseren Websites zu verbessern.   Dritte nutzen Cookies auf unseren Websites zu Werbezwecken. Dies wird nachfolgend näher beschrieben.</p>
              <p>Die spezifischen Arten von Erst- und Drittanbieter-Cookies, die über unsere Websites bereitgestellt werden, und die Zwecke, die sie erfüllen, sind in der nachstehenden Tabelle beschrieben (bitte beachten Sie, dass die spezifischen Cookies abhängig von der von Ihnen besuchten Website variieren können):</p>

              <table>
                <thead>
                  <tr>
                    <th>Arten von Cookies</th>
                    <th>Wer verwendet diese Cookies</th>
                    <th>How to refuse</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Unverzichtbare Website-Cookies:</strong> Diese Cookies sind unbedingt erforderlich, um Ihnen Dienste zur Verfügung zu stellen, die auf unseren Websites verfügbar sind, und einige der Funktionen der Websites zu nutzen, wie z. B. den Zugang zu sicheren Bereichen.</td>
                    <td>Avery Dennison</td>
                    <td>Da diese Cookies unbedingt notwendig sind, um die Websites bereitzustellen, können Sie sie nicht ablehnen.</td>
                  </tr>
                  <tr>
                    <td><strong>Leistungs- und Funktions-Cookies:</strong> Diese Cookies werden verwendet, um die Leistung und Funktionalität unserer Websites zu verbessern, sind jedoch für deren Verwendung nicht wesentlich.  Ohne diese Cookies können bestimmte Funktionen jedoch nicht verfügbar sein.</td>
                    <td>Avery Dennison</td>
                    <td>Um diese Cookies abzulehnen, folgen Sie bitte den Anweisungen unter „Wie kann ich Cookies kontrollieren?“</td>
                  </tr>
                  <tr>
                    <td><strong>Werbe-Cookies: </strong> Diese Cookies werden verwendet, um Ihnen relevantere Werbebotschaften anzuzeigen.  Sie führen Funktionen aus, die zum Beispiel verhindern, dass die gleiche Anzeige immer wieder erscheint, sicherstellen, dass die Anzeige für den Werbetreibenden korrekt angezeigt wird und in einigen Fällen wählen sie Werbeanzeigen aus, die auf Ihren Interessen basieren.</td>
                    <td>Avery Dennison</td>
                    <td>Um diese Cookies abzulehnen, folgen Sie bitte den Anweisungen unter „Wie kann ich Cookies kontrollieren?“</td>
                  </tr>
                </tbody>
              </table>

              <h3>Was ist mit anderen Tracking-Technologien wie Web Beacons?</h3>

              <p>Cookies sind nicht die einzige Möglichkeit, Besucher einer Website zu erkennen oder zu verfolgen.  Wir können von Zeit zu Zeit andere, ähnliche Technologien verwenden, wie z. B. Web Beacons (manchmal auch „Tracking Pixel“ oder „Clear Gifs“ genannt).  Dies sind winzige Grafikdateien, die eine eindeutige Kennung enthalten, mit der wir erkennen können, wenn jemand unsere Websites besucht hat. Dies ermöglicht uns beispielsweise, die Zugriffsmuster von Nutzern von einer Seite innerhalb unserer Websites auf andere zu überwachen, Cookies zu übermitteln oder mit ihnen zu kommunizieren, zu verstehen, ob Sie von einer Online-Anzeige auf einer Website eines Drittanbieters zu unseren Websites gekommen sind, die Leistung der Website zu verbessern und den Erfolg von E-Mail-Marketingkampagnen zu messen.  In vielen Fällen sind diese Technologien auf Cookies angewiesen, so dass eine Ablehnung der Cookies ihre Funktionsweise beeinträchtigt.</p>

              <h3>nterstützen Sie gezielte Werbung?</h3>

              <p>Dritte können Cookies auf Ihrem Computer oder Mobilgerät verwenden, um Werbung über unsere Websites zu schalten. Diese Unternehmen können Informationen über Ihre Besuche auf dieser und anderen Websites verwenden, um relevante Anzeigen über Waren und Dienstleistungen, an denen Sie interessiert sein könnten, bereitzustellen. Sie können auch eine Technologie einsetzen, mit der die Wirksamkeit von Werbung gemessen wird. Dies kann durch die Verwendung von Cookies oder Web Beacons erreicht werden, um Informationen über Ihre Besuche auf dieser und anderen Websites zu erfassen, um Ihnen damit relevante Werbung für Waren und Dienstleistungen zu liefern, die für Sie von Interesse sein könnten. Die durch diesen Prozess gesammelten Informationen ermöglichen es uns und Dritten nicht, Ihren Namen, Ihre Kontaktdaten oder andere personenbezogene Daten zu identifizieren, außer wenn Sie sich dafür entscheiden, diese anzugeben.</p>

              <h3>Wie kann ich Cookies kontrollieren?</h3>

              <p>Sie haben das Recht zu entscheiden, ob Sie Cookies akzeptieren oder ablehnen.  Sie können Ihre Cookie-Einstellungen vornehmen, indem Sie auf die entsprechenden Abmeldelinks in der obigen Cookie-Tabelle klicken.</p>

              <p>Sie können die Steuerung Ihres Webbrowsers so einstellen oder ändern, dass er Cookies akzeptiert oder ablehnt. Wenn Sie sich dazu entscheiden, Cookies abzulehnen, können Sie unsere Website trotzdem nutzen, obwohl Ihr Zugriff auf einige Funktionen und Bereiche unserer Website eingeschränkt sein kann.   Da die Methoden, mit denen Sie Cookies über die Einstellungen Ihres Webbrowsers ablehnen können, von Browser zu Browser variieren, sollten Sie im Hilfemenü Ihres Browsers nach weiteren Informationen suchen.</p>

              <p>Darüber hinaus bieten Ihnen die meisten Werbenetzwerke die Möglichkeit, gezielte Werbung abzulehnen.  Weitere Informationen dazu finden Sie unter http://www.aboutads.info/choices/ oder http://www.youronlinechoices.com.</p>

              <h3>Wie oft aktualisieren Sie diese Cookie-Erklärung?</h3>

              <p>Wir können diese Cookie-Erklärung von Zeit zu Zeit aktualisieren, um beispielsweise Änderungen bei den von uns verwendeten Cookies oder aus anderen operativen, rechtlichen oder regulatorischen Gründen zu berücksichtigen.  Bitte schauen Sie daher regelmäßig in diese Cookie-Erklärung, um sich über die Verwendung von Cookies und verwandten Technologien zu informieren.</p>

              <p>Das Datum oben in dieser Cookie-Erklärung gibt an, wann diese zuletzt aktualisiert wurde. </p>

              <h3>Wo kann ich weitere Informationen erhalten?</h3>

              <p>Wenn Sie Fragen zur Verwendung von Cookies oder anderen Technologien haben, senden Sie uns bitte eine E-Mail an GDPR.info@averydennison.com</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { firebaseanalytics } from '../firebase';
import Modal from "@/components/Modal.vue";
export default {
  name: 'CookieBanner',
  computed: {
    userLanguage: function(){
      return this.$i18n.locale;
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      cookiePopup: false
    };
  },
  methods: {
    updateCookies(val) {
      this.$store.dispatch("setCookies", val);

      if (val == true) {
        firebaseanalytics.setAnalyticsCollectionEnabled(true)
      } else {
        firebaseanalytics.setAnalyticsCollectionEnabled(false)
      }
    }
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
  .cookie_banner {
    position: fixed;
    bottom: 10px;
    background-color: $colorWhite;
    right: 10px;
    left: auto;
    padding: 10px;
    box-shadow: 0 -2px 2px rgba(0,0,0,0.06),
              0 2px 2px rgba(0,0,0,0.06), 
              0 4px 4px rgba(0,0,0,0.06), 
              0 8px 8px rgba(0,0,0,0.06);
    z-index: 997;
    max-width: calc(100% - 20px);
    width: 420px;
    transition: all 0.5s 0.5s;
  }

  .sticky_footer ~ .cookie_banner {
    bottom: 74px;
  }

  .logged_out .cookie_banner {
    @include screenSmall {
      width: calc(55% - 20px);
    }
    @include screenMedium {
      width: calc(66.67% - 20px);
    }
  }

  p {
    margin-bottom: 8px;
    line-height: 1.2;
    font-size: 13px;

    a {
      color: $colorRed;
    }
  }
  .btn-link {
    padding: 0;
    min-width: 0;

    &:hover {
      color: $colorRed;
      text-decoration: underline;
    }
  }
  .btn-link:first-of-type {
    margin-right: 20px;
  }

  .terms_popup {
    h3 {
      margin-top: 20px;
    }
    ul + p {
      margin-top: 20px;
    }

    h2 {
      margin-bottom: 20px;
    }
    table {
      margin-top: 35px;
    }

    td, th {
      min-width: 210px;
      text-align: left;

      &:first-child {
        max-width: 800px;
        padding-right: 25px;
        padding-bottom: 20px;
      }
    }
    td {
      @include copyDefault();

    }
  }
</style>

<i18n>
{
  "en" : {
    "usesCookies" : "This site uses cookies to provide you with a great user experience.",
    "accept" : "I accept the use of cookies.",
    "decline" : "I decline the use of cookies.",
    "preferences" : "You can update your cookie preferences in your profile settings.",
    "view" : "View our cookie policy for more details."
  },
  "fr" : {
    "usesCookies" : "Ce site utilise des cookies pour vous garantir une expérience utilisateur optimale.",
    "accept" : "J’accepte l’utilisation des cookies.",
    "decline" : "Je refuse l’utilisation de cookies.",
    "preferences" : "Vous pouvez mettre à jour vos préférences en matière de cookies dans les paramètres de votre profil.",
    "view" : "Reportez-vous à notre politique relative aux cookies pour plus de détails."
  },
  "zh" : {
    "usesCookies" : "此网站使用 Cookie 为您提供出色的用户体验。",
    "accept" : "我接受使用 Cookie。",
    "decline" : "我拒绝使用 Cookie。",
    "preferences" : "您可以在配置文件设置中更新 Cookie 偏好设置。",
    "view" : "请查看我们的 Cookie 政策，了解更多详细信息。"
  },
  "gr" : {
    "usesCookies" : "Die Website nutzt Cookies, damit wir Ihnen eine großartige Nutzererfahrung bieten können.",
    "accept" : "Ich akzeptiere die Verwendung von Cookies.",
    "decline" : "Ich lehne die Verwendung von Cookies ab.",
    "preferences" : "Sie können Ihre Cookie-Einstellungen in Ihrer Profileinstellung aktualisieren.",
    "view" : "Für weitere Details sehen Sie sich unsere Cookie-Richtlinie an."
  }
}
</i18n>