<template>
  <div>
    <picture>
      <source srcset="/assets/modals/connected.webp" type="image/webp" media="(min-width: 700px)" />
      <source srcset="/assets/modals/connected.jpg" type="image/jpeg" media="(min-width: 700px)" />
      <source srcset="/assets/modals/connected_mobile.webp" type="image/webp" />
      <img class="img-fluid" src="/assets/modals/connected_mobile.jpg" :alt="$t('imgAlt')">
    </picture>
    <div class="red_bg"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h1 class="text-center">{{ $t('title') }}</h1>

          <p v-for="(item, index) in $t('copy1')" v-html="item"></p>
          
          <ul>
            <li v-for="(item, index) in $t('list1')" v-html="item"></li>
          </ul>

          <h3 class="red" v-html="$t('heading1')"></h3>

          <vue-plyr class="video_wrapper" ref="plyr">
            <video playsinline data-poster="/assets/modals/Atma_Cover_1300.jpg">

              <source v-if="windowWidth < 426" size="426" :src="'/assets/modals/Avery_Atma' + lang + '_426.mp4'" type="video/mp4">
              <source v-else-if="windowWidth < 640" size="640" :src="'/assets/modals/Avery_Atma' + lang + '_640.mp4'" type="video/mp4">
              <source v-else-if="windowWidth < 960" size="960" :src="'/assets/modals/Avery_Atma' + lang + '_960.mp4'" type="video/mp4">
              <source v-else-if="windowWidth < 1280" size="1280" :src="'/assets/modals/Avery_Atma' + lang + '_1280.mp4'" type="video/mp4">
              <source v-else size="1920" :src="'/assets/modals/Avery_Atma' + lang + '_1920.mp4'" type="video/mp4">

              <track kind="captions" label="English" srclang="en" src="/assets/modals/Avery_Atma-en.vtt" default>
              <track kind="captions" label="French" srclang="fr" src="/assets/modals/Avery_Atma-fr.vtt">
            </video>
          </vue-plyr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Connected',
  data() {
    return {
      windowWidth: 0,
      lang: ''
    }
  },
  computed: {
    player () {
      return this.$refs.plyr.player
    },
  },
  methods: {
    setVideoLanguage(){
      let lang = this.$i18n.locale
      if (lang != 'en') {
        this.player.captions.active = true;
      }
      this.player.captions.language = lang;

      if (lang == 'zh') {
        this.lang = '_zh'
      }
    },
  },
  mounted () {
    var $this = this
    this.windowWidth = window.innerWidth

    this.setVideoLanguage()
  }
}
</script>

<style lang="scss" scoped>
.modal picture {
  margin-top: -50px;
  display: block;
  img {
    display: block;
    width: 100%;
    max-width: none;
  }
}
.red_bg {
  padding: 25px 50px;
  margin-bottom: 50px;
}

ul {
  margin-bottom: 25px;
  margin-top: 20px;
}

h3 {
  margin-top: 30px;
}
</style>

<i18n>
{
  "en" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "Resilience in the Connected Supply Chain",
    "copy1" : [
      "In today’s supply chain, it is critical to have full product visibility from source to final destination. This requires a comprehensive connected supply chain where each product has a unique digital identifier to enable product data capture, interpretation, storage, sharing and connectivity. ",
      "The future lies in connected end-to-end digital solutions.  Avery Dennison provides holistic data-driven solutions to build a resilient supply chain.  Pairing every physical item with a unique digital twin creates a connected product and a connected consumer.  Digitizing the goods is the key to unlock full supply chain connectivity:"
    ],
    "list1" : [
      "Enable full visibility across your supply chain. ",
      "Achieve maximized inventory accuracy. ",
      "Deliver a level of transparency that builds credibility around your business.",
      "<strong>Ensure a connected supply chain.</strong>"
    ],
    "heading1" : "Made Possible with atma.io by Avery Dennison<sup>&reg;</sup> "
  },
  "fr" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "Résilience dans la chaîne d’approvisionnement connectée",
    "copy1" : [
      "Dans la chaîne d’approvisionnement actuelle, la visibilité totale sur les produits est primordiale, de la source à la destination finale. Pour y parvenir, la chaîne d’approvisionnement doit être connectée et complète, autrement dit chaque produit possède un identifiant numérique unique qui permet la capture des données du produit, le stockage, le partage et la connectivité.",
      "L’avenir repose sur des solutions numériques connectées de bout en bout.  Avery Dennison® propose des solutions guidées par les données pour construire une chaîne d’approvisionnement résiliente.  L’association de chaque article physique à un ID numérique unique crée un produit et un client connectés.  La numérisation des biens est la solution pour débloquer la connectivité de la chaîne d’approvisionnement globale :"
    ],
    "list1" : [
      "Favorisez une visibilité totale de l’ensemble de votre chaîne d’approvisionnement. ",
      "Visez une précision des stocks plus affinée. ",
      "Offrez un niveau de transparence qui renforce la crédibilité de votre entreprise. ",
      "<strong>Proposez une chaîne d’approvisionnement connectée.</strong>"
    ],
    "heading1" : "Ceci est rendu possible grâce à atma.io d’Avery Dennison<sup>&reg;</sup>"
  },
  "zh" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "互联供应链中的韧性",
    "copy1" : [
      "在如今的供应链中，全面了解产品从来源到最终目的地的整个流程至关重要。这需要全面的互联供应链，要求其中每个产品都具有独一无二的数字标识符，从而实现产品数据捕获、解释、存储、共享和连接性。",
      "未来属于互联的端到端数字解决方案。艾利丹尼森提供全面的数据驱动型解决方案，以建立有韧性的供应链。通过将每一个实体项目与独一无二的数字孪生体配对，打造互联的产品和互联的消费者。实现商品数字化是释放全部供应链连接性的关键："
    ],
    "list1" : [
      "全面了解您的供应链",
      "实现最大限度的库存准确性",
      "提供高水平透明度，围绕您的业务建立可信度",
      "<strong>确保采用互联供应链</strong>"
    ],
    "heading1" : "艾利丹尼森以 atma.io 成就无限可能"
  },
  "gr" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "Resilienz der vernetzten Supply Chain",
    "copy1" : [
      "Bei der Supply Chain von heute spielt die Produkttransparenz vom Erzeuger bis zum endgültigen Bestimmungsort eine entscheidende Rolle. Das erfordert eine umfassend vernetzte Supply Chain, in der jedes Produkt eine einzigartige digitale Kennung hat, um eine Erfassung der Produktdaten, deren Auslegung, Speicherung, Teilen und Konnektivität möglich zu machen.",
      "Die Zukunft liegt in vernetzten, digitalen End-to-End-Lösungen. Avery Dennison bietet ganzheitliche Daten-Lösungen für eine robuste Supply Chain. Die Verbindung jedes physischen Artikels mit einem einzigartigen digitalen Twin sorgt für vernetzte Produkte und vernetzte Verbraucher. Die Digitalisierung von Waren legt den Grundstein für eine vollkommen vernetzte Supply Chain."
    ],
    "list1" : [
      "Sorgen Sie für komplette Transparenz in Ihrer gesamten Supply Chain.",
      "Erreichen Sie maximale Bestandsgenauigkeit.",
      "Mit einem hohen Maß an Transparenz unterstreichen Sie die Glaubwürdigkeit Ihres Geschäft.",
      "<strong>Sorgen Sie für eine vernetzte Supply Chain.</strong>"
    ],
    "heading1" : "Möglich gemacht mit atma.io bei Avery Dennison<sup>&reg;</sup> "
  }
}
</i18n>